import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ configData = [], type }) => {
  console.log(configData, 'configData');
  const chartData = {
    options: {
      chart: {
        id: 'basic-bar',
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: configData.data.map((val) => val.x_axis),
        labels: {
          style: {
            colors: configData.data.map((val) => '#fff'),
          },
        },
        title: {
          text: configData.data[0]?.x_axis_label || '',
          style: {
            color: '#fff',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: ['#fff'],
          },
        },
        title: {
          text: configData.data[0]?.statsType || '',
          style: {
            color: '#fff',
            fontFamily: 'Helvetica, Arial',
          },
        },
        tickAmount: 5, // Specify the number of ticks to display (adjust as needed)
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        labels: {
          colors: '#fff',
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      fill: {
        colors: ['#7d7d7d'],
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
      },
    },
    series: [
      {
        name: configData.data[0]?.statsType || '',
        data: configData.data.map((val) => val.data),
        color: configData.color,
      },
    ],
    dataLabels: {
      enabled: true,
    },
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type={type}
        height="300px"
      />
    </div>
  );
};

export default BarChart;
