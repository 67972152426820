import { toast } from 'react-toastify';
import { apolloClient } from '../apolloClient';
import {
  CREATE_TENANT_USER,
  UPDATE_TENANT_USER,
  DELETE_TENANT_USER,
} from '../user';

export const createTenantUser = async ({
  tenantId,
  firstname,
  lastname,
  email,
  roleId,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_TENANT_USER,
      variables: {
        tenantId: tenantId,
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: 'Test@12345',
        roleId: roleId,
      },
    });
    toast.success('User created successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('Invalid firstname or lastname')) {
      toast.error('Invalid firstname or lastname', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const updateTenantUser = async ({
  id,
  firstname,
  lastname,
  status,
  roleId,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_TENANT_USER,
      variables: {
        editTenantUserId: id,
        firstname: firstname,
        lastname: lastname,
        status: status,
        roleId: roleId,
      },
    });
    toast.success('User updated successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('Invalid firstname or lastname')) {
      toast.error('Invalid firstname or lastname', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const deleteTenantUser = async (id) => {
  try {
    const response = await apolloClient.mutate({
      mutation: DELETE_TENANT_USER,
      variables: {
        deleteTenantUserId: id,
      },
    });
    toast.success('User deleted successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    console.error(error.message);
    // Handle login error
    throw new Error(error.message);
  }
};
