import axios from '../Utils/api';

const ApplicationService = {
  getApplicationsList: (page, search, sort) => {
    return axios.get(
      `/applications/get-application?page=${page}&search=${search}&sort=${sort}`
    );
  },

  getAllApplicationsList: () => {
    return axios.get(`/applications/get-all-application`);
  },

  updateApplication: (appHashId, applicationData) => {
    return axios.put('/applications/update-application', applicationData, {
      headers: {
        apphashid: appHashId,
      },
    });
  },

  deleteApplication: (appHashId) => {
    return axios.post(
      '/applications/delete-application',
      {},
      {
        headers: {
          applicationhashid: appHashId,
        },
      }
    );
  },
};

export default ApplicationService;
