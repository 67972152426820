import React from 'react';
import { Helmet } from 'react-helmet';
import { ForgotPasswordStyle } from '../../Layouts/style';
import { Box, Button, Typography } from '@mui/material';
import { RightArrow } from '../../Icons/SVGContainer';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const navigate = useNavigate();
  const LoginUser = () => {
    navigate('/', { replace: true });
  };

  return (
    <ForgotPasswordStyle>
      <Helmet>
        <title>Mirasys: Reset password</title>
      </Helmet>
      <Box className="forgot_pass_area">
        <Box className="forgot_box_con">
          <Box className="forgot_heading">
            <img src="/images/logo.svg" alt="" />
            <Typography component="h2">Password reset</Typography>
            <Typography>
              Your password has been successfully reset .<br /> Click below to
              login.{' '}
            </Typography>
          </Box>
          <form>
            <Box className="form_box">
              <Button
                className="submitBtn  bg-primary-hover"
                onClick={LoginUser}
              >
                Continue
                <RightArrow />
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </ForgotPasswordStyle>
  );
};

export default ResetPassword;
