import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, CircularProgress, Pagination, Stack } from '@mui/material';
import dayjs from 'dayjs';
import io from 'socket.io-client';
import { UserStyle } from '../../../Layouts/style';
import AlertHeading from './AlertsHeading';
import AlertsDataTable from '../../../Components/DataTable/AlertsDataTable';
import AnalyticsService from '../../../RestSevices/analyticsService';
import AlertsService from '../../../RestSevices/alertService';
import { toast } from 'react-toastify';
import useAuth from '../../../Redux/Actions/authActions';
import capitalizeFirstLetter from '../../../Utils/utilities';
import FilterSidebar from './FilterSidebar';

const Alerts = () => {
  const [loading, setLoading] = useState(false);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [analyticsSelection, SetAnalyticsSelection] = useState('');
  const [alerts, setAlerts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterDateStart, setFilterDateStart] = useState(null);
  const [filterDateEnd, setFilterDateEnd] = useState(null);
  const [serachTerm, setSearchTerm] = useState('');
  const [displayFields, setDisplayFields] = useState([]);

  const { logout } = useAuth();

  useEffect(() => {
    console.log('socketUrl', process.env.REACT_APP_API_SOCKET_URL);
    const socketUrl = process.env.REACT_APP_API_SOCKET_URL;
    const socket = io(socketUrl);

    // Handle socket connection
    socket.on('connect', () => {
      console.log('Socket connected');
    });

    // Handle socket disconnection
    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    // Handle socket errors
    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    socket.on('alert_created', (data) => {
      // let dateFilter;
      // if (!fromDate && !toDate) {
      //   dateFilter = null;
      // }
      // console.log('dateFilter', dateFilter);
      // console.log('serachTerm', serachTerm);
      // if (dateFilter === null && serachTerm === '') {
      //   setAlerts((prevAlerts) => [...prevAlerts, data]);
      // }
      const analytics = localStorage.getItem('analyticsSelection');
      SetAnalyticsSelection(analytics);
      setPage(1);
      setFromDate(null);
      setToDate(null);
      setSearchTerm('');
      fetchAlerts();
      console.log('test11111', analytics);
    });

    return () => {
      console.log('Socket disconnect');
      socket.disconnect();
    };
  }, [serachTerm, filterDateStart, filterDateEnd]);

  useEffect(() => {
    fetchAnalytics();
    // const analytic = localStorage.getItem('analyticsSelection');
    // SetAnalyticsSelection(analytic);
  }, []);

  useEffect(() => {
    if (filterDateStart && filterDateEnd) {
      console.log('filterDateStart', filterDateStart);
      console.log('filterDateEnd', filterDateEnd);
      const startDateObj = new Date(filterDateStart);
      const startTimestamp = startDateObj.getTime();
      const endDateObj = new Date(filterDateEnd);
      const endTimestamp = endDateObj.getTime();
      if (startTimestamp > endTimestamp) {
        toast.error('Start date cannot be greater than end date');
        setFilterDateEnd(null);
        return;
      }
    }
    fetchAlerts();
  }, [analyticsSelection, page, serachTerm, filterDateStart, filterDateEnd]);

  const handleAnalyticsSelection = (value) => {
    const analyticName = value;
    if (analyticName) {
      const analytic = analyticsList.find(
        (analytic) => analytic.analytic_name === analyticName
      );
      SetAnalyticsSelection(analyticName);
      AnalyticsService.getAnalyticsPostFields(analytic.analytic_id).then(
        (resp) => {
          setDisplayFields(resp.data.data);
        }
      );
      localStorage.setItem('analyticsSelection', analyticName);
    } else {
      SetAnalyticsSelection('');
      setAlerts([]);
      setTotalPages(0);
      setDisplayFields([]);
      localStorage.removeItem('analyticsSelection');
    }
  };

  const getTimestamp = (date) => {
    const dateObj = new Date(date);

    // Check if dateObj is a valid date
    if (isNaN(dateObj.getTime())) {
      throw new Error('Invalid date');
    }
    const gmtDate = new Date(
      Date.UTC(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate(),
        dateObj.getHours(),
        dateObj.getMinutes(),
        dateObj.getSeconds(),
        dateObj.getMilliseconds()
      )
    );

    // Convert to Unix timestamp in seconds
    return Math.floor(gmtDate.getTime() / 1000);
  };

  const fetchAlerts = () => {
    console.log('111');
    console.log('analyticsSelection', analyticsSelection);
    if (
      !filterDateStart &&
      !filterDateEnd &&
      (analyticsSelection || localStorage.getItem('analyticsSelection'))
    ) {
      console.log('222');
      const dateFilter = null;
      let search = null;
      if (serachTerm) {
        search = serachTerm;
      }
      AlertsService.getAlerts({
        analyticsSelection,
        page,
        dateFilter: dateFilter,
        search,
      })
        .then((resp) => {
          console.log('resp_data', resp.data);
          setAlerts(resp.data);
          setTotalPages(resp.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setLoading(false);
        });
    }
    if (
      filterDateStart &&
      filterDateEnd &&
      dayjs(filterDateStart).isBefore(filterDateEnd) &&
      analyticsSelection
    ) {
      console.log('333');
      const dateFilter = {
        start: filterDateStart ? new Date(filterDateStart).getTime() : null,
        end: filterDateEnd ? new Date(filterDateEnd).getTime() : null,
      };
      let search = null;
      if (serachTerm) {
        search = serachTerm;
      }
      setLoading(true);
      AlertsService.getAlerts({ analyticsSelection, page, search, dateFilter })
        .then((resp) => {
          setAlerts(resp.data);
          setTotalPages(resp.totalPages);
          setLoading(false);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching alerts:', error);
          setLoading(false);
        });
    }
  };

  const fetchAnalytics = () => {
    AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateStart(formattedDate);
    setToDate(null);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      : null;
    setFilterDateEnd(formattedDate);
  };

  const handleOnClickSearch = () => {
    setPage(1);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const resetFilter = () => {
    setToDate(null);
    setFromDate(null);
    setFilterDateStart(null);
    setFilterDateEnd(null);
    setSearchTerm('');
    fetchAlerts();
  };

  const columns = ['thumbnailUrl', 'cameraName', 'zoneName'];
  const columnsToDisplay = columns;
  displayFields.forEach((field) => {
    if (
      field.field_name !== 'thumb_url' &&
      field.field_name !== 'image_url' &&
      field.is_displayed === 1
    ) {
      columnsToDisplay.push(field.field_name);
    }
  });
  const headCells = [
    {
      id: 'thumbnailUrl',
      numeric: false,
      disablePadding: true,
      label: 'Thumbnail URL',
    },
    {
      id: 'cameraName',
      numeric: false,
      disablePadding: true,
      label: 'Camera Name',
    },
    {
      id: 'zoneName',
      numeric: false,
      disablePadding: true,
      label: 'Zone Name',
    },
  ];
  const columnHeads = headCells;
  displayFields.forEach((field) => {
    if (
      field.field_name !== 'thumb_url' &&
      field.field_name !== 'image_url' &&
      field.is_displayed === 1
    ) {
      columnHeads.push({
        id: field.field_name,
        numeric: false,
        disablePadding: true,
        label: capitalizeFirstLetter(field.field_name),
      });
    }
  });
  // console.log('alerts', alerts)
  // if(localStorage.getItem('analyticsSelection') && !analyticsSelection) {
  //   SetAnalyticsSelection(localStorage.getItem('analyticsSelection'));
  // }
  console.log('analyticsSelection_test', analyticsSelection);
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Alerts</title>
      </Helmet>
      <FilterSidebar />
      <Box className="user_area">
        <AlertHeading
          analytics={analyticsList}
          handleAnalyticsSelection={handleAnalyticsSelection}
          analyticsSelection={analyticsSelection}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          fromDate={fromDate}
          toDate={toDate}
          serachTerm={serachTerm}
          handleSearch={handleSearch}
          resetFilter={resetFilter}
          handleOnClickSearch={handleOnClickSearch}
        />
        {loading ? (
          <CircularProgress color="success" />
        ) : (
          <AlertsDataTable
            rows={alerts}
            headCells={columnHeads}
            columns={columnsToDisplay}
            analyticsSelection={analyticsSelection}
          />
        )}
        {analyticsSelection && (
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        )}
      </Box>
    </UserStyle>
  );
};

export default Alerts;
