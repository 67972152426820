import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import { MainLayoutStyle } from './style';
import { Box } from '@mui/material';

const MainLayout = () => {
  return (
    <MainLayoutStyle>
      <Box className="main_area">
        <Header />
        <Sidebar />
        <Outlet />
      </Box>
    </MainLayoutStyle>
  );
};

export default MainLayout;
