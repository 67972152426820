import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AddIcon, SearchIcon, CloseIcon } from '../../../Icons/SVGContainer';
import { DatatableHeadingStyle } from '../../../Layouts/style';
import BaseModal from '../../../Components/Modal/BaseModal';
import AddUserForm from '../../../Components/Form/AddUserForm';
import {
  generateUserListPdf,
  generateUsersListExcel,
} from '../../../graphQL/User/query';
import { checkUserManagementPermission } from '../../../Utils/checkPermission';
import { getAllRoles } from '../../../graphQL/Role/query';

const UserManagementHeading = ({
  fetchUsers,
  handleChangeList,
  filter,
  search,
  sortBy,
  sortOrder,
}) => {
  const [status, setStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [roles, setRoles] = useState([]);
  const [exportType, setExportType] = useState('');
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [role, setRole] = useState('');

  useEffect(() => {
    handleChangeList(role, status, searchTerm);
  }, [status, role, searchTerm]);

  useEffect(() => {
    if (exportType === 'pdf') {
      handleExportPDF();
      setExportType('');
    } else if (exportType === 'excel') {
      handleExportExcel();
      setExportType('');
    }
  }, [exportType]);
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  // Handle search change with debounce
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const clearSearchTerm = () => {
    const clearedSearchTerm = '';
    setSearchTerm(clearedSearchTerm);
  };

  const handleExportType = (event) => {
    setExportType(event.target.value);
  };

  const handleExportPDF = () => {
    generateUserListPdf({ sortBy, sortOrder, filter, search }).then(
      (response) => {
        if (response.data && response.data.generate_users_pdf) {
          // Convert the base64 string to a Uint8Array
          const base64Data = response.data.generate_users_pdf;
          const binaryString = atob(base64Data);
          const length = binaryString.length;
          const bytes = new Uint8Array(length);
          for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Create a Blob from the Uint8Array
          const blob = new Blob([bytes], { type: 'application/pdf' });

          // Create a temporary anchor element and trigger download
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'users.pdf');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          console.error('PDF data not found in response');
        }
      }
    );
  };

  const handleExportExcel = () => {
    generateUsersListExcel({ sortBy, sortOrder, filter, search }).then(
      (response) => {
        if (response.data && response.data.generate_users_excel) {
          // Convert the base64 string to a Uint8Array
          const base64Data = response.data.generate_users_excel;
          const binaryString = atob(base64Data);
          const length = binaryString.length;
          const bytes = new Uint8Array(length);
          for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Create a Blob from the Uint8Array
          const blob = new Blob([bytes], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          // Create a temporary anchor element and trigger download
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'users.xlsx'); // Adjust the filename and extension as needed
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          console.error('Excel data not found in response');
        }
      }
    );
  };

  const fetchRoles = async () => {
    const resp = await getAllRoles();
    if (!('error' in resp)) {
      setRoles(resp.data.getAllRoles.roles);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <DatatableHeadingStyle>
      <Box className="user_heading">
        <Typography component="h2">User management</Typography>
        <Box className="user_head_search">
          <Box className="page_search">
            <TextField
              type="text"
              placeholder="Searching for....."
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchTerm && (
                  <IconButton onClick={clearSearchTerm}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box className="table_head_btn_con">
            <Select value={status} onChange={handleStatusChange} displayEmpty>
              <MenuItem value="">Select Status</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
            <Select value={role} onChange={handleRoleChange} displayEmpty>
              <MenuItem value="">Select Role</MenuItem>
              {roles.map(
                (role) =>
                  role.active && (
                    <MenuItem key={role.id} value={role.id}>
                      {role.title}{' '}
                    </MenuItem>
                  )
              )}
            </Select>
            <Select value={exportType} onChange={handleExportType} displayEmpty>
              <MenuItem value="">Export Listing</MenuItem>
              <MenuItem value="pdf">PDF</MenuItem>
              <MenuItem value="excel">EXCEL</MenuItem>
            </Select>
            {checkUserManagementPermission('add_user') && (
              <IconButton onClick={() => setModalOpen(true)}>
                <AddIcon /> Add User
              </IconButton>
            )}
          </Box>
          <BaseModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title={`Add User`}
          >
            <AddUserForm
              onClose={() => setModalOpen(false)}
              fetchUsers={fetchUsers}
            />
          </BaseModal>
        </Box>
      </Box>
    </DatatableHeadingStyle>
  );
};

export default UserManagementHeading;
