import { gql } from '@apollo/client';

export const GET_ROLES = gql`
  query GetRoles(
    $sortBy: String
    $sortOrder: String
    $search: String
    $page: Int
  ) {
    getRoles(
      sortBy: $sortBy
      sortOrder: $sortOrder
      search: $search
      page: $page
    ) {
      count
      roles {
        id
        title
        active
        createdAt
        updatedAt
        rolePermissions {
          permission {
            id
            slug
            title
            type
            module
          }
        }
      }
      totalPages
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query GetAllRoles {
    getAllRoles {
      roles {
        id
        title
        active
        createdAt
        updatedAt
        rolePermissions {
          permission {
            id
            slug
            title
            type
            module
          }
        }
      }
    }
  }
`;

export const GET_ROLE = gql`
  query GetRole($getRoleId: ID) {
    getRole(id: $getRoleId) {
      id
      title
      active
      createdAt
      updatedAt
      rolePermissions {
        permission {
          id
          slug
          title
          type
          module
        }
        rolePermissionObjects {
          id
          objectId
          objectType
          roleId
          rolePermissionId
        }
      }
    }
  }
`;

export const GET_USERS_BY_ROLE = gql`
  query GetUsersByRole($getUsersByRoleId: ID) {
    getUsersByRole(id: $getUsersByRoleId) {
      users {
        id
        firstname
        lastname
        email
        status
      }
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation Mutation($title: String!, $status: Int!) {
    createRole(title: $title, status: $status) {
      success
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation EditRole($editRoleId: ID!, $title: String!, $status: Int!) {
    editRole(id: $editRoleId, title: $title, status: $status) {
      success
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($deleteRoleId: ID!) {
    deleteRole(id: $deleteRoleId) {
      success
    }
  }
`;
