import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ModalLayoutStyle } from '../../Layouts/style';
import CameraService from '../../RestSevices/cameraService';
import useAuth from '../../Redux/Actions/authActions';
import { addCameraValidator } from '../../Utils/validators';

const EditCameraForm = ({ onClose, fetchData, cameraForEdit }) => {
  const [hashId] = useState(cameraForEdit?.hash_id || '');
  const newCameraId = uuidv4();
  const { logout } = useAuth();
  const [predictions, setPredictions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
    googleMapsScript.async = true;
    googleMapsScript.defer = true;
    googleMapsScript.onload = () => {
      console.log('Google Maps API loaded');
    };
    document.body.appendChild(googleMapsScript);

    return () => {
      document.body.removeChild(googleMapsScript);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      camera_id: cameraForEdit?.camera_id || newCameraId,
      camera_name: cameraForEdit?.camera_name || '',
      rstp_url: cameraForEdit?.rstp_url || '',
      fps: cameraForEdit?.fps || '',
      location: cameraForEdit?.location || '',
    },
    validationSchema: addCameraValidator,
    onSubmit: async (values) => {
      try {
        if (hashId) {
          const data = {
            ...values,
            hash_id: hashId,
          };
          setIsSubmitting(true);
          const result = await CameraService.updateCamera(hashId, data);
          if (result.status === 200) {
            fetchData();
            onClose();
            toast.success('Camera updated successfully!', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);
          }
        } else {
          setIsSubmitting(true);
          const result = await CameraService.createCamera(
            values,
            values.camera_id
          );
          if (result.status === 201) {
            fetchData();
            onClose();
            toast.success('Camera created successfully!', {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);
          }
        }
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 401) {
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
          logout();
        } else if (err.response.status === 400) {
          toast.error(err.response?.data?.error, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
        } else {
          toast.error('Error in creating camera', {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
        }
      }
    },
  });

  const handleLocationChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue('location', value);
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      {
        input: value,
        types: ['(regions)'],
        componentRestrictions: { country: 'IN' },
      },
      async (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          try {
            const predictionsWithLatLong = await Promise.all(
              predictions.map(async (prediction) => ({
                ...prediction,
                latlong: await getPlaceLatLong(prediction.place_id),
                label: prediction.description,
              }))
            );
            setPredictions(predictionsWithLatLong);
          } catch (error) {
            console.error('Error fetching place details:', error);
            setPredictions([]);
          }
        } else {
          setPredictions([]);
        }
      }
    );
  };

  const getPlaceLatLong = (placeId) => {
    return new Promise((resolve, reject) => {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
      placesService.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          resolve(`${lat},${lng}`);
        } else {
          reject(new Error('Error fetching place details'));
        }
      });
    });
  };

  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <form onSubmit={formik.handleSubmit}>
          <Box className="form_field_area">
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Camera Id</Typography>
                <TextField
                  name="camera_id"
                  value={formik.values.camera_id || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.camera_id && Boolean(formik.errors.camera_id)
                  }
                  helperText={
                    formik.touched.camera_id && formik.errors.camera_id
                  }
                  disabled={hashId ? true : false}
                />
              </Box>
              <Box className="form_box_con">
                <Typography component="label">Camera Name</Typography>
                <TextField
                  name="camera_name"
                  value={formik.values.camera_name || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.camera_name &&
                    Boolean(formik.errors.camera_name)
                  }
                  helperText={
                    formik.touched.camera_name && formik.errors.camera_name
                  }
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Rtsp Url</Typography>
                <TextField
                  name="rstp_url"
                  value={formik.values.rstp_url || ''}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.rstp_url && Boolean(formik.errors.rstp_url)
                  }
                  helperText={formik.touched.rstp_url && formik.errors.rstp_url}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">FPS</Typography>
                <TextField
                  name="fps"
                  value={formik.values.fps || ''}
                  onChange={formik.handleChange}
                  error={formik.touched.fps && Boolean(formik.errors.fps)}
                  helperText={formik.touched.fps && formik.errors.fps}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Location</Typography>
                <Autocomplete
                  options={predictions}
                  value={formik.values.location || ''}
                  onChange={(e, newValue) => {
                    formik.setFieldValue(
                      'location',
                      newValue ? newValue.latlong : ''
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="location"
                      onChange={handleLocationChange}
                      value={formik.values.location || ''}
                      error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                      }
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box className="add_btn">
            <Button className="cancel_btn" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {hashId ? 'Update' : 'Create'}
            </Button>
          </Box>
        </form>
      </Box>
    </ModalLayoutStyle>
  );
};

export default EditCameraForm;
