import {
  Box,
  Checkbox,
  TextField,
  Typography,
  Button,
  Menu,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { DatatableHeadingStyle } from '../../../Layouts/style';
import AnalyticsService from '../../../RestSevices/analyticsService';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TuneIcon from '@mui/icons-material/Tune';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { StyledHeaderParent, StyledHeader } from './style';
import { SearchIcon } from '../../../Icons/SVGContainer';
import dayjs from 'dayjs';

const VideoSummarizationHeading = ({
  analytics,
  setAnalytics,
  fromDate,
  toDate,
  handleFromDateChange,
  handleToDateChange,
  resetFilter,
  searchVSAlertTerm,
  handleVSAlertSearch,
  handleSearchTerm,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAnalytics, setFilteredAnalytics] = useState(analytics);

  useEffect(() => {
    setFilteredAnalytics(analytics);
  }, [analytics]);

  useEffect(() => {
    AnalyticsService.getAnalyticsBelongsToVS().then((resp) => {
      const data = [];
      const analytics_data = resp.data?.data;
      analytics_data.map((analytic) => {
        const analytic_classes = JSON.parse(analytic.classes);
        const classes = analytic_classes.map((cls) => {
          return {
            value: cls,
            checked: false,
          };
        });

        data.push({
          analytic: analytic.analytic_name,
          classes,
        });
      });
      setAnalytics(data);
    });
  }, []);

  const handleReset = () => {
    const data = [];
    analytics.forEach((analytic) => {
      const classes = analytic.classes.map((cls) => ({
        value: cls.value,
        checked: false,
      }));

      data.push({
        analytic: analytic.analytic,
        classes,
      });
    });
    setAnalytics(data);
    resetFilter();
  };

  const handleCheckboxChange = (analyticsIndex, classIndex) => {
    const status =
      filteredAnalytics[analyticsIndex].classes[classIndex].checked;
    setAnalytics((prevSelected) => {
      return prevSelected.map((analytic, index1) => {
        if (index1 === analyticsIndex) {
          return {
            ...analytic,
            classes: analytic.classes.map((cls, index2) => {
              if (index2 === classIndex) {
                return {
                  ...cls,
                  checked: !status,
                };
              }
              return cls;
            }),
          };
        }
        return analytic;
      });
    });
  };
  const isAnyClassChecked = analytics.some((analytic) => {
    return analytic.classes.some((cls) => cls.checked);
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    const filteredData = analytics.filter((analytic) => {
      const searchText = newSearchTerm;
      const analyticNameLower = analytic.analytic.toLowerCase();
      return (
        analyticNameLower.includes(searchText) ||
        analytic.classes.some((cls) =>
          cls.value.toLowerCase().includes(searchText)
        )
      );
    });

    setFilteredAnalytics(filteredData);
  };

  return (
    <DatatableHeadingStyle>
      <Box className="user_heading business_heading">
        <Typography component="h2">Video Summarization</Typography>
      </Box>
      <StyledHeaderParent>
        <Box className="search-part">
          <TextField
            className="search"
            type="text"
            placeholder="Searching for....."
            value={searchVSAlertTerm}
            onChange={handleSearchTerm}
            // onClick={handleOnClickSearch}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
          <Button onClick={handleVSAlertSearch} disabled={!searchVSAlertTerm}>
            Search
          </Button>
        </Box>
        <StyledHeader>
          <Menu
            id="analytics-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'analytics-button',
            }}
          >
            <Box
              sx={(theme) => ({
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginBottom: '1rem',

                '.MuiFormLabel-root , input': {
                  color: theme.colors.invertColor.color1,
                },

                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.colors.invertColor.color1,
                },
              })}
            >
              <TextField
                label="Analytics"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ width: '100%' }}
              />
            </Box>
            <TreeView
              sx={{
                maxHeight: '20rem',
                overflow: 'auto',

                '.MuiCollapse-vertical': {
                  display: 'flex',
                  flexDirection: 'column',
                },
              }}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {filteredAnalytics &&
                filteredAnalytics.map((analytic, index1) => {
                  return (
                    <TreeView
                      key={analytic.analytic}
                      aria-label={`tree-view-${index1}`}
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      sx={{
                        '&:not(:first-child)': {
                          marginTop: '1rem',
                        },
                        paddingLeft: '1rem',
                        paddingRight: '1rem',

                        '.MuiCollapse-root': {
                          marginLeft: 0,
                        },
                      }}
                    >
                      <TreeItem
                        nodeId={`node-${index1}`}
                        label={analytic.analytic}
                      >
                        {analytic &&
                          analytic.classes.map((cls, index2) => (
                            <TreeItem
                              onClick={() =>
                                handleCheckboxChange(index1, index2)
                              }
                              key={cls.value}
                              nodeId={`node-${index1}-${index2}`}
                              icon={<Checkbox checked={cls.checked} />}
                              label={cls.value}
                            />
                          ))}
                      </TreeItem>
                    </TreeView>
                  );
                })}
            </TreeView>
          </Menu>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                label="From Date"
                value={fromDate}
                onChange={handleFromDateChange}
                disableFuture
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                label="To Date"
                value={toDate}
                onChange={handleToDateChange}
                minDate={fromDate ? dayjs(fromDate) : null}
                disabled={!fromDate}
                disableFuture
              />
            </DemoContainer>
          </LocalizationProvider>
          <Button
            aria-controls="analytics-menu"
            aria-haspopup="true"
            onClick={handleClick}
            sx={(theme) => ({ backgroundColor: 'black !important' })}
          >
            <TuneIcon />
          </Button>
          <Button
            onClick={handleReset}
            disabled={
              !fromDate && !toDate && !isAnyClassChecked && !searchVSAlertTerm
            }
          >
            {' '}
            Reset{' '}
          </Button>
        </StyledHeader>
      </StyledHeaderParent>
    </DatatableHeadingStyle>
  );
};

export default VideoSummarizationHeading;
