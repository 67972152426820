import axios from '../Utils/api';

const DashboardService = {
  getChartDataById: async (id, range, refresh = false) => {
    // Build the base URL
    const url = `dashboard/get-dashboard-stats/${id}`;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Make the request using the built URL
    const response = await axios.get(url, {
      params: {
        range: range,
        timezone: timezone,
        refresh: refresh,
      },
    });
    return response.data;
  },
};

export default DashboardService;
