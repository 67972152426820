import React, { useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import { Box } from '@mui/material';

const AriaChart = () => {
  const options = {
    series: [
      {
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#fff'],
        },
      },
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2018-09-19T00:00:00.000Z',
        '2018-09-19T01:00:00.000Z',
        '2018-09-19T02:00:00.000Z',
        '2018-09-19T03:00:00.000Z',
        '2018-09-19T04:00:00.000Z',
        '2018-09-19T05:00:00.000Z',
        '2018-09-19T06:00:00.000Z',
      ],
      labels: {
        style: {
          colors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        },
      },
    },
    legend: {
      show: false,
    },
    // tooltip: {
    //     x: {
    //         format: 'dd/MM/yy HH:mm',
    //     },
    //     style: {
    //         colors: ["#000"]
    //     }
    // },
  };

  const series = options.series;

  return (
    <Box>
      <ApexCharts
        options={options}
        series={series}
        type={options.chart.type}
        height={options.chart.height}
      />
    </Box>
  );
};

export default AriaChart;
