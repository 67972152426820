import { Box, Typography } from '@mui/material';
import React from 'react';
import { DatatableHeadingStyle } from '../../../Layouts/style';

const ZoneConfigurationHeading = () => {
  return (
    <DatatableHeadingStyle>
      <Box className="user_heading business_heading">
        <Typography component="h2">Zone Configuration</Typography>
      </Box>
    </DatatableHeadingStyle>
  );
};

export default ZoneConfigurationHeading;
