import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  FormHelperText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';
import {
  createTenantUser,
  updateTenantUser,
} from '../../graphQL/User/mutation';
import { Formik } from 'formik';
import {
  addUserValidationSchema,
  editUserValidationSchema,
} from '../../Utils/validators';
import { getAllRoles } from '../../graphQL/Role/query';

const AddUserForm = ({ onClose, fetchUsers, initialData = {} }) => {
  const [addUserState, setAddUserState] = useState({
    firstname: '',
    lastname: '',
    email: '',
    roleId: [],
    status: '',
  });

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (initialData.id) {
      setAddUserState({
        firstname: initialData.firstname,
        lastname: initialData.lastname,
        email: initialData.email,
        roleId: initialData?.userRoles[0]?.role
          ? initialData.userRoles.map((roledata) => parseInt(roledata.role.id))
          : [],
        status: initialData.status,
      });
    }
  }, [initialData]);

  const handleSubmit = (values, { resetForm }) => {
    // If no errors, handle form submission here
    if (initialData.id) {
      updateTenantUser({
        id: initialData.id,
        firstname: values.firstname.trim(),
        lastname: values.lastname.trim(),
        status: values.status,
        roleId: values.roleId,
      })
        .then(() => {
          onClose();
          resetForm();
          fetchUsers();
        })
        .catch((error) => { });
    } else {
      createTenantUser({
        tenantId: 1,
        firstname: values.firstname.trim(),
        lastname: values.lastname.trim(),
        email: values.email.trim(),
        roleId: values.roleId,
      })
        .then((data) => {
          onClose();
          fetchUsers();
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const fetchRoles = async () => {
    const resp = await getAllRoles();
    if (!('error' in resp)) {
      setRoles(resp.data.getAllRoles.roles);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Formik
          initialValues={addUserState}
          validationSchema={
            initialData.id ? editUserValidationSchema : addUserValidationSchema
          }
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Box className="form_field_area">
                <Box className="form_box_hold">
                  <Box className="form_box_con full_width">
                    <Typography component="label">First Name</Typography>
                    <TextField
                      name="firstname"
                      type="text"
                      value={props.values.firstname}
                      onChange={props.handleChange}
                      error={
                        props.touched.firstname && !!props.errors.firstname
                      }
                      helperText={
                        props.touched.firstname ? props.errors.firstname : ''
                      }
                    />
                  </Box>
                  <Box className="form_box_con full_width">
                    <Typography component="label">Last Name</Typography>
                    <TextField
                      name="lastname"
                      type="text"
                      value={props.values.lastname}
                      onChange={props.handleChange}
                      error={props.touched.lastname && !!props.errors.lastname}
                      helperText={
                        props.touched.lastname ? props.errors.lastname : ''
                      }
                    />
                  </Box>
                </Box>
                <Box className="form_box_hold">
                  <Box className="form_box_con full_width">
                    <Typography component="label">Email id</Typography>
                    <TextField
                      name="email"
                      type="email"
                      value={props.values.email}
                      onChange={(e) => {
                        // Convert the entered email value to lowercase
                        const lowercasedEmail = e.target.value.toLowerCase();
                        // Call the Formik handleChange with the new lowercase value
                        props.handleChange({
                          target: {
                            name: 'email',
                            value: lowercasedEmail,
                          },
                        });
                      }}
                      error={props.touched.email && !!props.errors.email}
                      helperText={props.touched.email ? props.errors.email : ''}
                      disabled={initialData.id || false}
                    />
                  </Box>
                </Box>
                <Box className="form_box_con">
                  <Typography component="label">Role</Typography>
                  <Select
                    className='invert-border'
                    name="roleId"
                    value={props.values.roleId}
                    onChange={props.handleChange}
                    error={props.touched.roleId && !!props.errors.roleId}
                    displayEmpty
                    multiple
                  >
                    <MenuItem value="" disabled>
                      <em>Select</em>
                    </MenuItem>
                    {roles.map((role) => (
                      role.active && <MenuItem value={parseInt(role.id)} key={role.id}>
                        {role.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    error={props.touched.roleId && !!props.errors.roleId}
                  >
                    {props.touched.roleId ? props.errors.roleId : ''}
                  </FormHelperText>
                </Box>
                {initialData.id && (
                  <Box className="form_box_con">
                    <Typography component="label">Status</Typography>
                    <Select
                      className='invert-border'
                      name="status"
                      value={props.values.status}
                      onChange={props.handleChange}
                      error={props.touched.status && !!props.errors.status}
                      helperText={
                        props.touched.status ? props.errors.status : ''
                      }
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={'Active'}>Active</MenuItem>
                      <MenuItem value={'Inactive'}>Inactive</MenuItem>
                    </Select>
                    <FormHelperText
                      error={props.touched.status && !!props.errors.status}
                    >
                      {props.touched.status ? props.errors.status : ''}
                    </FormHelperText>
                  </Box>
                )}
              </Box>
              <Box className="add_btn">
                <Button className="cancel_btn" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">
                  {initialData.id ? 'Update' : 'Create'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </ModalLayoutStyle>
  );
};

export default AddUserForm;
