import React, { useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ActiveIcon,
  AddIcon,
  DeleteIcon,
  DropAnalyticsIcon,
  DropConfigureIcon,
  DropDeleteIcon,
  DropEditIcon,
  DropPreviewIcon,
  DropStopIcon,
  EditIcon,
  OpenBorderEyeIcon,
  SettingIcon,
} from '../../Icons/SVGContainer';
import { checkHasObjPermission } from '../../Utils/checkPermission';

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CameraListDataTable = ({
  rows,
  headCells,
  columns,
  handleEdit,
  handleDetailsForm,
  handleClose,
  handleDeleteConfirm,
  setClickedCamera,
  handlePreviewChange,
  handleConfigure,
  handleAnalyticChange,
}) => {
  const visibleRows = rows;

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={'medium'}
        className="datatable_common"
      >
        <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
        <TableBody>
          {visibleRows.map((row) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: 'pointer' }}
              >
                {columns.map((col, index) => (
                  <TableCell align={index === 0 ? 'left' : 'right'} key={col}>
                    {col === 'action' ? (
                      <Box className="action_camera_icon">
                        {checkHasObjPermission(
                          row,
                          'camera',
                          'view_camera'
                        ) && (
                          <IconButton
                            title="Details"
                            onClick={() => {
                              setClickedCamera(row?.hash_id);
                              handleDetailsForm(row?.hash_id);
                            }}
                          >
                            <DropStopIcon />
                          </IconButton>
                        )}
                        {checkHasObjPermission(
                          row,
                          'camera',
                          'edit_camera'
                        ) && (
                          <IconButton
                            title="Edit"
                            onClick={() => {
                              setClickedCamera(row?.hash_id);
                              handleEdit(row?.hash_id);
                            }}
                          >
                            <DropEditIcon />
                          </IconButton>
                        )}
                        {(checkHasObjPermission(row, 'camera', 'create_zone') ||
                          checkHasObjPermission(row, 'camera', 'edit_zone') ||
                          checkHasObjPermission(
                            row,
                            'camera',
                            'delete_zone'
                          )) && (
                          <IconButton
                            title="Configure"
                            onClick={() => handleConfigure(row?.hash_id)}
                          >
                            <DropConfigureIcon />
                          </IconButton>
                        )}
                        <IconButton
                          title="Analytics"
                          onClick={handleAnalyticChange}
                        >
                          <DropAnalyticsIcon />
                        </IconButton>
                        <IconButton
                          title="Preview"
                          onClick={handlePreviewChange}
                        >
                          <DropPreviewIcon />
                        </IconButton>
                        {checkHasObjPermission(
                          row,
                          'camera',
                          'delete_camera'
                        ) && (
                          <IconButton
                            title="Delete"
                            onClick={() => {
                              setClickedCamera(row?.hash_id);
                              handleDeleteConfirm();
                            }}
                          >
                            <DropDeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    ) : (
                      row[col]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CameraListDataTable;
