import { toast } from 'react-toastify';
import { apolloClient } from '../apolloClient';
import { CREATE_ROLE, DELETE_ROLE, EDIT_ROLE } from '../role';
import { ASSIGN_PERMISSION } from '../permission';
import {
  CREATE_DASHBOARD_SETTINGS,
  DELETE_DASHBOARD_SETTINGS,
  UPDATE_DASHBOARD_SETTINGS,
} from '../dashboard';

export const createDashboardSettings = async ({
  name,
  parameter,
  stats_type,
  chart,
  x_axis,
  y_axis,
  chart_parameters,
  analytics_id,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_DASHBOARD_SETTINGS,
      variables: {
        name,
        parameter,
        stats_type,
        chart,
        x_axis,
        y_axis,
        chart_parameters,
        analytics_id,
      },
    });
    toast.success('Settings created successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const updateDashboardSettings = async ({
  id,
  name,
  parameter,
  stats_type,
  chart,
  x_axis,
  y_axis,
  chart_parameters,
  analytics_id,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_DASHBOARD_SETTINGS,
      variables: {
        id,
        name,
        parameter,
        stats_type,
        chart,
        x_axis,
        y_axis,
        chart_parameters,
        analytics_id,
      },
    });
    toast.success('Settings updated successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const deleteDashboardSettings = async (id) => {
  try {
    const response = await apolloClient.mutate({
      mutation: DELETE_DASHBOARD_SETTINGS,
      variables: {
        id,
      },
    });
    toast.success('Settings deleted successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Setting title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};
