import { Box, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import React from 'react';
import { ChangePasswordStyle } from '../../../Layouts/style';
import PasswordField from '../../../Components/PasswordField';
import useAuth from '../../../Redux/Actions/authActions';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { ChangePasswordValidationSchema } from '../../../Utils/validators';

const UserChangePassword = () => {
  const { change_password } = useAuth();
  const navigate = useNavigate();
  const ChangePasswordState = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await change_password(values);
      if (response.data.changeTenantPassword) {
        navigate('/user-list', { replace: true });
      }
      // Optionally, reset the form after successful password change
      resetForm();
    } catch (error) {
      // Handle error if needed
      console.error('Error during password change:', error);
    }
  };
  return (
    <ChangePasswordStyle>
      <Helmet>
        <title>Mirasys: Change password</title>
      </Helmet>
      <Box className="change_pass_area">
        <Box className="heading">
          <Typography component="h2">Change password</Typography>
        </Box>
        <Box className="change_pass_con">
          <Formik
            initialValues={ChangePasswordState}
            validationSchema={ChangePasswordValidationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box className="form_box">
                  <PasswordField
                    label="Old Password"
                    name="old_password"
                    placeholder="Type old password"
                    onChange={props.handleChange}
                    error={
                      props.touched.old_password && !!props.errors.old_password
                    }
                    helperText={
                      props.touched.old_password
                        ? props.errors.old_password
                        : ''
                    }
                  />
                </Box>
                <Box className="form_box">
                  <PasswordField
                    label="New Password"
                    name="new_password"
                    placeholder="Type new password"
                    onChange={props.handleChange}
                    error={
                      props.touched.new_password && !!props.errors.new_password
                    }
                    helperText={
                      props.touched.new_password
                        ? props.errors.new_password
                        : ''
                    }
                  />
                </Box>
                <Box className="form_box">
                  <PasswordField
                    label="Confirm Password"
                    name="confirm_password"
                    placeholder="Type confirm password"
                    onChange={props.handleChange}
                    error={
                      props.touched.confirm_password &&
                      !!props.errors.confirm_password
                    }
                    helperText={
                      props.touched.confirm_password
                        ? props.errors.confirm_password
                        : ''
                    }
                  />
                </Box>
                <Box className="form_box">
                  <Button className="submitBtn  bg-primary-hover" type="submit">
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </ChangePasswordStyle>
  );
};

export default UserChangePassword;
