import axios from '../Utils/api';

const CameraService = {
  createCamera: (data, cameraHashId) => {
    return axios.post('/cameras/create-camera', data, {
      headers: {
        cameraid: cameraHashId,
      },
    });
  },

  getCameraDetailsByHash: (hashId) => {
    return axios.get(`/cameras/get-camera-by-hash/${hashId}`);
  },

  getCameraList: (page, search, sort) => {
    return axios.get(
      `/cameras/get-camera?page=${page}&search=${search}&sort=${sort}`
    );
  },

  getAllCameraList: (page, search, sort) => {
    return axios.get(`/cameras/get-all-camera`);
  },

  updateCamera: (cameraHashId, cameraData) => {
    return axios.put('/cameras/update-camera', cameraData, {
      headers: {
        camerahashid: cameraHashId,
      },
    });
  },

  deleteCamera: (cameraHashId) => {
    return axios.post(
      '/cameras/delete-camera',
      {},
      {
        headers: {
          camerahashid: cameraHashId,
        },
      }
    );
  },
};

export default CameraService;
