import { useDispatch, useSelector } from 'react-redux';
import { apolloClient } from '../../graphQL/apolloClient';
import { selectUser, selectIsAuthenticated, actions } from '../Slice/auth';
import {
  TENANT_CHANGE_PASSWORD_MUTATION,
  TENANT_FORGOT_PASSWORD_MUTATION,
  TENANT_LOGIN_MUTATION,
  TENANT_LOGOUT_MUTATION,
  TENANT_RESET_PASSWORD_MUTATION,
} from '../../graphQL/auth';
import { toast } from 'react-toastify';

export default function useAuth() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const tenant_login = async ({ email, password }) => {
    try {
      const response = await apolloClient.mutate({
        mutation: TENANT_LOGIN_MUTATION,
        variables: {
          email: email,
          password: password,
        },
      });
      if ('errors' in response) {
        toast.error(response.errors[0].message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success('Logged in successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(
          actions.login({
            token: response.data.tenantUserLogin.token,
            user: response.data.tenantUserLogin.user,
          })
        );
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.message.includes('User not found')) {
        toast.error('Invalid email or password. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      if (error.message.includes('Invalid password')) {
        toast.error('Invalid password. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (error.message.includes('Account is inactive')) {
        toast.error(
          'Account is inactive. Please contact support for assistance.',
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const forgot_password = async (email) => {
    try {
      const response = await apolloClient.mutate({
        mutation: TENANT_FORGOT_PASSWORD_MUTATION,
        variables: {
          email: email,
        },
      });
      return response;
    } catch (error) {
      if (error.message.includes('User not found')) {
        toast.error('Invalid email. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.error('Login error:', error);
    }
  };

  const reset_password = async ({
    passwordResetToken,
    password,
    confirmPassword,
  }) => {
    try {
      const response = await apolloClient.mutate({
        mutation: TENANT_RESET_PASSWORD_MUTATION,
        variables: {
          passwordResetToken: passwordResetToken,
          password: password,
          confirmPassword: confirmPassword,
        },
      });
      return response;
    } catch (error) {
      console.error('Login error:', error);
      // Handle login error
    }
  };

  const change_password = async ({
    old_password,
    new_password,
    confirm_password,
  }) => {
    try {
      const response = await apolloClient.mutate({
        mutation: TENANT_CHANGE_PASSWORD_MUTATION,
        variables: {
          old_password: old_password,
          new_password: new_password,
          confirm_password: confirm_password,
        },
      });
      if ('errors' in response) {
        toast.error(response.errors[0].message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success('Password changed successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      // Dispatch the action to update the user in the Redux store
      dispatch(
        actions.updateUser({ user: response.data.changeTenantPassword.user })
      );
      // Return the response object
      return response;
    } catch (error) {
      console.error('Change password error:', error);
      if (error.message.includes('Wrong old password')) {
        toast.error('Wrong old password entered', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const logout = async () => {
    try {
      const response = await apolloClient.mutate({
        mutation: TENANT_LOGOUT_MUTATION,
      });
      if ('errors' in response) {
        toast.error(response.errors[0].message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success('Logged out successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(actions.logout());
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return {
    tenant_login,
    logout,
    forgot_password,
    reset_password,
    change_password,
    isAuthenticated,
    user,
  };
}
