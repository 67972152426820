import {
    Box,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from '@mui/material';
  import React, { useState, useEffect } from 'react';
  import { AddIcon, SearchIcon, CloseIcon } from '../../../Icons/SVGContainer';
  import { DatatableHeadingStyle } from '../../../Layouts/style';
  import BaseModal from '../../../Components/Modal/BaseModal';
import AddBusinessForm from '../../../Components/Form/AddBusinessForm';
  
  const AdminBusinessHeading = () => {
    
    const [modalOpen, setModalOpen] = useState(false);
    
  
    return (
      <DatatableHeadingStyle>
        <Box className="user_heading business_heading">
          <Typography component="h2">Admin Business</Typography>
          <Box className="table_head_btn_con">
              <IconButton onClick={() => setModalOpen(true)}>
                Create Profile
              </IconButton>
            </Box>
            <BaseModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              title={`Create`}
            >
              <AddBusinessForm
                onClose={() => setModalOpen(false)}
              />
            </BaseModal>
        </Box>
      </DatatableHeadingStyle>
    );
  };
  
  export default AdminBusinessHeading;
  