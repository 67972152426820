import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({ configData = [], type }) => {
  // Check if type is undefined and set a default value
  const chartType = type || 'pie';

  // Your data for the line chart
  const chartData = {
    labels: configData.data.map((val) => val.name),
    series: configData.data.map((val) => val.data),
    colors: configData.data.map((val) => val.color),
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      labels: {
        colors: '#fff',
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };

  return (
    <div>
      <Chart
        options={chartData}
        series={chartData.series}
        type={chartType}
        height="300px"
      />
    </div>
  );
};

export default PieChart;
