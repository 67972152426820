import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query GetUsers(
    $sortBy: String
    $sortOrder: String
    $filter: T_UserFilter
    $search: String
    $page: Int
  ) {
    tenant_users(
      sortBy: $sortBy
      sortOrder: $sortOrder
      filter: $filter
      search: $search
      page: $page
    ) {
      users {
        email
        id
        firstname
        lastname
        status
        username
        userType
        userRoles {
          role {
            id
            title
            active
            createdAt
            updatedAt
          }
        }
        createdByUser {
          id
          firstname
          lastname
        }
      }
      count
      totalPages
    }
  }
`;

export const CREATE_TENANT_USER = gql`
  mutation CreateTenantUser(
    $tenantId: Int!
    $email: String!
    $password: String!
    $roleId: [ID]!
    $firstname: String!
    $lastname: String!
  ) {
    createTenantUser(
      tenantId: $tenantId
      email: $email
      password: $password
      roleId: $roleId
      firstname: $firstname
      lastname: $lastname
    ) {
      email
      firstname
      id
      lastname
      status
    }
  }
`;

export const UPDATE_TENANT_USER = gql`
  mutation UpdateTenantUser(
    $editTenantUserId: ID!
    $status: String
    $firstname: String
    $lastname: String
    $roleId: [ID]
  ) {
    editTenantUser(
      id: $editTenantUserId
      status: $status
      firstname: $firstname
      lastname: $lastname
      roleId: $roleId
    ) {
      email
      firstname
      id
      lastname
      status
    }
  }
`;

export const DELETE_TENANT_USER = gql`
  mutation DeleteTenantUser($deleteTenantUserId: ID!) {
    deleteTenantUser(id: $deleteTenantUserId)
  }
`;

export const GENERATE_USER_LIST_PDF = gql`
  query GenerateUserListPdf(
    $sortBy: String
    $sortOrder: String
    $filter: T_UserFilter
    $search: String
  ) {
    generate_users_pdf(
      sortBy: $sortBy
      sortOrder: $sortOrder
      filter: $filter
      search: $search
    )
  }
`;

export const GENERATE_USER_LIST_EXCEL = gql`
  query generateUsersListExcel(
    $sortBy: String
    $sortOrder: String
    $filter: T_UserFilter
    $search: String
  ) {
    generate_users_excel(
      sortBy: $sortBy
      sortOrder: $sortOrder
      filter: $filter
      search: $search
    )
  }
`;
