import React from 'react';

// const TimestampToDate = ({ timestamp }) => {
//   console.log('timestamp', timestamp);
//   // Convert the timestamp to milliseconds
//   const date = new Date(timestamp * 1000);

//   // Extract UTC date components
//   const day = date.getUTCDate();
//   const month = date.getUTCMonth() + 1; // Months are zero-indexed in JavaScript
//   const year = date.getUTCFullYear();
//   let hours = date.getUTCHours();
//   const minutes = date.getUTCMinutes();
//   const seconds = date.getUTCSeconds();

//   // Format hours to 12-hour format with AM/PM
//   const ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'

//   // Add leading zeros to minutes and seconds if necessary
//   const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
//   const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

//   // Format the date string
//   const formattedDate = `${day}/${month}/${year}, ${hours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

//   return (
//     <div>
//       <p>{formattedDate}</p>
//     </div>
//   );
// };

const TimestampToDate = ({ timestamp }) => {
  // Convert the timestamp to milliseconds
  const date = new Date(timestamp * 1000);

  // Extract local date components
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed in JavaScript
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Format hours to 12-hour format with AM/PM
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Add leading zeros to minutes and seconds if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  // Format the date string
  const formattedDate = `${day}/${month}/${year}, ${hours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

  return (
    <div>
      <p>{formattedDate}</p>
    </div>
  );
};

export default TimestampToDate;
