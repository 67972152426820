import { gql } from '@apollo/client';

export const GET_STATS_TYPE = gql`
  query StatsTypes {
    getAllStatsType {
      StatsTypes {
        id
        title
      }
    }
  }
`;

export const GET_ALL_DASHBOARD_SETTINGS_BY_ANALYTICS = gql`
  query GetAllDashboardSettingsByAnalytic(
    $getAllDashboardSettingsByAnalyticId: ID!
  ) {
    getAllDashboardSettingsByAnalytic(
      id: $getAllDashboardSettingsByAnalyticId
    ) {
      dashboardSettings {
        id
        analytics_id
        name
        parameter
        stats_type
        chart
        x_axis
        y_axis
        chart_parameters
        statsType {
          title
        }
      }
    }
  }
`;

export const GET_ALL_DASHBOARD_SETTINGS_BY_ANALYTICS_IDS = gql`
  query GetAllDashboardSettingsByAnalyticByIds($ids: [ID]!) {
    getAllDashboardSettingsByAnalyticIds(ids: $ids) {
      dashboardSettings {
        name
        chart_parameters
        chart
        analytics_id
        parameter
        stats_type
        x_axis
        y_axis
        statsType {
          id
          title
        }
        id
      }
    }
  }
`;

export const CREATE_DASHBOARD_SETTINGS = gql`
  mutation Mutation(
    $name: String
    $parameter: String
    $stats_type: String
    $chart: String
    $x_axis: String
    $y_axis: String
    $chart_parameters: [String]
    $analytics_id: String
  ) {
    createDashboardSettings(
      name: $name
      parameter: $parameter
      stats_type: $stats_type
      chart: $chart
      x_axis: $x_axis
      y_axis: $y_axis
      chart_parameters: $chart_parameters
      analytics_id: $analytics_id
    ) {
      success
      data {
        id
      }
    }
  }
`;

export const UPDATE_DASHBOARD_SETTINGS = gql`
  mutation Mutation(
    $id: ID!
    $name: String
    $parameter: String
    $stats_type: String
    $chart: String
    $x_axis: String
    $y_axis: String
    $chart_parameters: [String]
    $analytics_id: String
  ) {
    updateDashboardSettings(
      id: $id
      name: $name
      parameter: $parameter
      stats_type: $stats_type
      chart: $chart
      x_axis: $x_axis
      y_axis: $y_axis
      chart_parameters: $chart_parameters
      analytics_id: $analytics_id
    ) {
      success
      data {
        id
      }
    }
  }
`;

export const DELETE_DASHBOARD_SETTINGS = gql`
  mutation DeleteDashboardSettings($id: ID!) {
    deleteDashboardSettings(id: $id) {
      success
    }
  }
`;
