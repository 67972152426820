import axios from '../Utils/api';

const AlertsService = {
  getAlerts: async ({
    analyticsSelection,
    page,
    sort,
    cameraId = null,
    dateFilter,
    search,
  }) => {
    if (!page) {
      page = 1;
    }
    if (!sort) {
      sort = 'desc';
    }
    // Build the base URL
    let url = '/alerts/get-alert-nvs';

    if (analyticsSelection !== 'All' && analyticsSelection) {
      url += `/${analyticsSelection}`;
    }
    // Build query parameters based on conditions
    const queryParams = [];
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (sort) {
      queryParams.push(`sortOrder=${sort}`);
    }
    if (cameraId) {
      queryParams.push(`camera_id=${cameraId}`);
    }

    if (dateFilter) {
      queryParams.push(`dateFilter=${JSON.stringify(dateFilter)}`);
    }

    if (search) {
      queryParams.push(`search=${search}`);
    }

    // Append query parameters to the URL if there are any
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    // Make the request using the built URL
    const response = await axios.get(url);
    return response.data;
  },

  getDashboardAlerts: async ({ analytics, range }) => {
    // Build the base URL
    let url = `/alerts/get-dashboard-alert-nvs/${analytics}`;
    // Build query parameters based on conditions
    const queryParams = [];
    if (range) {
      queryParams.push(`range=${range}`);
    }
    // Append query parameters to the URL if there are any
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    // Make the request using the built URL
    const response = await axios.get(url);
    return response.data;
  },

  getVsAlerts: async ({ page, sort, dateFilter, analytics, search }) => {
    if (!page) {
      page = 1;
    }
    if (!sort) {
      sort = 'desc';
    }
    let url = '/alerts/get-alert-vs';
    // Build query parameters based on conditions
    const queryParams = [];
    if (page) {
      queryParams.push(`page=${page}`);
    }
    if (sort) {
      queryParams.push(`sortOrder=${sort}`);
    }
    if (dateFilter) {
      queryParams.push(`dateFilter=${JSON.stringify(dateFilter)}`);
    }
    if (analytics) {
      queryParams.push(`analytics=${JSON.stringify(analytics)}`);
    }
    if (search) {
      queryParams.push(`search=${search}`);
    }

    // Append query parameters to the URL if there are any
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
    // Make the request using the built URL
    const response = await axios.get(url);
    return response.data;
  },
};

export default AlertsService;
