import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import UserManagementHeading from './UserManagementHeading';
import { Box, Typography } from '@mui/material';
import { UserStyle } from '../../../Layouts/style';
import UserDataTable from '../../../Components/DataTable/UserDataTable';
import { getAllUsers } from '../../../graphQL/User/query';
import { Pagination, Stack } from '@mui/material';
import BaseModal from '../../../Components/Modal/BaseModal';
import AddUserForm from '../../../Components/Form/AddUserForm';
import useAuth from '../../../Redux/Actions/authActions';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy] = useState('createdAt');
  const [sortOrder] = useState('DESC');
  const [filter, setFilter] = useState({ status: '', userType: '' });
  const [search, setSearch] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [editIntialData, setEditIntialData] = useState({});
  const { logout } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, [page, filter, search]);

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const fetchUsers = () => {
    getAllUsers({ sortBy, sortOrder, filter, search, page })
      .then((resp) => {
        if (resp) {
          const { data, errors } = resp;
          if (errors && errors[0].message === 'Invalid Token!') {
            logout();
          }
          if (data && data.tenant_users) {
            setUsers(data.tenant_users.users);
            setTotalPages(data.tenant_users.totalPages);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChangeList = (userType, status, searchVal) => {
    setFilter({ userType, status });

    setSearch(searchVal);
    setPage(1);
  };

  const handleEditForm = (user) => {
    setEditIntialData(user);
    setModalOpen(true);
  };
  const columns = [
    'fullName',
    'email',
    'roles',
    'status',
    'createdByUser',
    'action',
  ];
  const headCells = [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: true,
      label: 'Full Name',
    },
    {
      id: 'emailAddress',
      numeric: false,
      disablePadding: true,
      label: 'Email address',
    },
    {
      id: 'roles',
      numeric: false,
      disablePadding: true,
      label: 'Roles',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
    },
    {
      id: 'createdByUser',
      numeric: false,
      disablePadding: true,
      label: 'Created By',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Users</title>
      </Helmet>
      <Box className="user_area">
        <UserManagementHeading
          fetchUsers={fetchUsers}
          handleChangeList={handleChangeList}
          filter={filter}
          search={search}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
        {users.length === 0 ? (
          <Typography
            variant="h4"
            component="div"
            style={{ textAlign: 'center' }}
          >
            No user found
          </Typography>
        ) : (
          <>
            <UserDataTable
              rows={users}
              headCells={headCells}
              columns={columns}
              fetchUsers={fetchUsers}
              openForm={(user) => handleEditForm(user)}
            />
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </>
        )}

        <BaseModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title={`Edit User`}
        >
          <AddUserForm
            onClose={() => setModalOpen(false)}
            fetchUsers={fetchUsers}
            initialData={editIntialData}
          />
        </BaseModal>
      </Box>
    </UserStyle>
  );
};

export default UserList;
