import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DialogStyle } from '../../Layouts/style';
import { CloseIcon } from '../../Icons/SVGContainer';

/**
 * @typedef {import('@mui/material/Dialog').DialogProps} DialogProps
 * @typedef {Object} CustomDialogProps
 * @property {boolean} open
 * @property {React.ReactNode} [children]
 * @property {() => void} onClose
 * @property {number} [width]
 * @property {number} [height]
 * @property {string} [title]
 */

/**
 * @param {CustomDialogProps} props
 */
const UserListModal = ({ open, onClose, userList, width, height, title }) => {
  const [dialogHeight] = useState(height);
  const [dialogWidth] = useState(width ? width : '600px');
  return (
    <DialogStyle
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          height: dialogHeight,
          minWidth: dialogWidth,
          overflow: 'hidden',
          borderRadius: '20px',
          boxShadow: '0px 4px 40px 5px rgba(0, 0, 0, 0.13)',
        },
      }}
      sx={{
        '.MuiPaper-root': {
          minWidth: '700px !important',
        },
        '@media(max-width:1099px)': {
          '.MuiPaper-root': {
            minWidth: '60% !important',
          },
        },
        '@media(max-width:767px)': {
          '.MuiPaper-root': {
            minWidth: '90% !important',
          },
        },
      }}
    >
      <Box className="modal-head">
        <Box>
          {title && (
            <Typography className="global_modal_title">{title}</Typography>
          )}
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className="modal-body">
        <TableContainer>
          <Table
            sx={{ minWidth: 150 }}
            aria-labelledby="tableTitle"
            size={'medium'}
            className="datatable_common"
          >
            <TableHead>
              <TableRow className="table_head">
                <TableCell key={0} align={'left'} padding={'normal'}>
                  FirstName
                </TableCell>
                <TableCell key={0} align={'left'} padding={'normal'}>
                  LastName
                </TableCell>
                <TableCell key={0} align={'left'} padding={'normal'}>
                  Email Id
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.length ? (
                userList.map((user) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={user.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell key="title" align="right">
                        {user.firstname}
                      </TableCell>
                      <TableCell key="title" align="right">
                        {user.lastname}
                      </TableCell>
                      <TableCell key="title" align="right">
                        {user.email}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1} sx={{ cursor: 'pointer' }}>
                  <TableCell key="title" colSpan={3}>
                    <Box sx={{ textAlign: 'center' }}>No user found</Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </DialogStyle>
  );
};

export default UserListModal;
