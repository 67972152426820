import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';
import { UserStyle } from '../../../Layouts/style';
import { Pagination, Stack } from '@mui/material';
import BaseModal from '../../../Components/Modal/BaseModal';
import RoleHeading from './RoleHeading';
import RoleDataTable from '../../../Components/DataTable/RoleDataTable';
import AddRoleForm from '../../../Components/Form/AddRoleForm';
import { getRoles } from '../../../graphQL/Role/query';
import { deleteRole } from '../../../graphQL/Role/mutation';
import { toast } from 'react-toastify';
import useAuth from '../../../Redux/Actions/authActions';

const RoleList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy] = useState('createdAt');
  const [sortOrder] = useState('DESC');
  const [search, setSearch] = useState('');
  const [rolelist, setRoleList] = useState([]);
  const { logout } = useAuth();

  const columns = [
    'roleName',
    'viewUsers',
    'permissions',
    'lastUpdated',
    'status',
    'action',
  ];
  const headCells = [
    {
      id: 'roleName',
      numeric: false,
      disablePadding: true,
      label: 'Role Name',
    },
    {
      id: 'viewUsers',
      numeric: false,
      disablePadding: true,
      label: 'View Users',
    },
    {
      id: 'permissions',
      numeric: false,
      disablePadding: true,
      label: 'Permissions',
    },
    {
      id: 'lastUpdated',
      numeric: false,
      disablePadding: true,
      label: 'Last Updated on',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];

  const fetchRoles = async () => {
    const resp = await getRoles({ sortBy, sortOrder, search, page });
    if (resp.errors && resp.errors[0].message === 'Invalid Token!') {
      toast.error('Session Expired!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      logout();
      return;
    }
    setRoleList(resp.data.getRoles.roles);
    setTotalPages(resp.data.getRoles.totalPages);
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    fetchRoles();
  }, [page, search]);

  const handleChangeList = (searchVal) => {
    setSearch(searchVal);
    setPage(1);
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const onEdit = (id) => {
    setRoleId(id);
    setModalOpen(true);
  };

  const onDelete = async (id) => {
    try {
      const resp = await deleteRole(id);
      if (resp && !('error' in resp)) {
        fetchRoles();
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModal = (status, fetchData = false) => {
    setModalOpen(status);
    if (fetchData) {
      fetchRoles();
    }
  };
  return (
    <UserStyle>
      <Helmet>
        <title>Mirasys: Users</title>
      </Helmet>
      <Box className="user_area">
        <RoleHeading
          fetchRoles={fetchRoles}
          handleChangeList={handleChangeList}
        />
        {rolelist.length === 0 ? (
          <Typography
            variant="h4"
            component="div"
            style={{ textAlign: 'center' }}
          >
            No role found
          </Typography>
        ) : (
          <>
            <RoleDataTable
              roles={rolelist}
              headCells={headCells}
              columns={columns}
              onClick={onEdit}
              onDelete={onDelete}
            />
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </>
        )}

        <BaseModal
          open={modalOpen}
          onClose={(fetchData) => handleModal(false, fetchData)}
          title={`Edit Role`}
        >
          <AddRoleForm
            roleId={roleId}
            fetchRoles={fetchRoles}
            onClose={() => handleModal(false)}
          />
        </BaseModal>
      </Box>
    </UserStyle>
  );
};

export default RoleList;
