import {
  Box,
  MenuItem,
  Select,
  Typography,
  TextField,
  FormHelperText,
  Grid,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React, { useState, useEffect } from 'react';
import {
  DashboardForm,
  AddForm,
  InputBox,
  ButtonBox,
  Button,
} from '../../../Layouts/style';
import {
  getAllSettingsByAnalytics,
  getAllStatsType,
} from '../../../graphQL/Dashboard/query';
import { Formik } from 'formik';
import AnalyticsService from '../../../RestSevices/analyticsService';
import {
  createDashboardSettings,
  deleteDashboardSettings,
  updateDashboardSettings,
} from '../../../graphQL/Dashboard/mutation';
import DeleteDialog from '../../../Components/deleteDialog';
import useAuth from '../../../Redux/Actions/authActions';
import { toast } from 'react-toastify';
import { checkPermission } from '../../../Utils/checkPermission';
import { useNavigate } from 'react-router-dom';
import DashboardSettingsForm from './DashboardSettingsForm';

const DashboardSettings = () => {
  const [analytics, setAnalytics] = useState('');
  const [analyticsList, setAnalyticsList] = useState([]);
  const [statsTypes, setStatsTypes] = useState([]);
  const [classList, setClassList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [settingsId, setSettingsId] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleChange = (event, index, frmkProps) => {
    const { name, value } = event.target;
    setForms(
      forms.map((form, index2) => {
        if (index2 == index) {
          form[name] = value;
          if (name == 'chart') {
            form['chart_parameters'] = [];
            form['x_axis'] = '';
            form['y_axis'] = '';
            if (value == 'pie_chart') {
              form['parameter'] = 'none';
            }
          }
          return form;
        } else {
          return form;
        }
      })
    );
    frmkProps.handleChange(event);
  };

  const formTemplate = {
    id: '',
    name: '',
    parameter: 'none',
    stats_type: '',
    chart: 'no_chart',
    x_axis: '',
    y_axis: '',
    chart_parameters: [],
    analytics_id: analytics,
  };

  const [forms, setForms] = useState([]);

  const addForm = () => {
    setForms([...forms, formTemplate]);
  };

  const fetchSettings = async () => {
    const settingsresp = await getAllSettingsByAnalytics(analytics);
    if ('errors' in settingsresp) {
      if (settingsresp?.errors[0].message == 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }

    const settingsData =
      settingsresp?.data?.getAllDashboardSettingsByAnalytic?.dashboardSettings?.map(
        (forms) => {
          return {
            ...forms,
            chart_parameters: forms.chart_parameters
              ? forms.chart_parameters.split(',')
              : [],
          };
        }
      );
    setForms(settingsData || []);
  };

  useEffect(() => {
    if (analytics) {
      fetchSettings();
    } else {
      setForms([]);
    }
  }, [analytics]);

  const fetchData = async () => {
    const statsTypeResp = await getAllStatsType();
    if ('errors' in statsTypeResp) {
      if (statsTypeResp?.errors[0].message == 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    setStatsTypes(statsTypeResp.data?.getAllStatsType?.StatsTypes || []);

    await AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data || []);
      })
      .catch((err) => {
        if (err.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handleAnalyticsChange = (event) => {
    const selectedValue = event.target.value;
    setAnalytics(selectedValue);

    if (selectedValue) {
      const selectedAnalytic = analyticsList.find(
        (analytic) => analytic.hash_id === selectedValue
      );

      if (selectedAnalytic) {
        const classField = selectedAnalytic.analyticfields.find(
          (field) => field.field_name === 'classes'
        );

        if (classField) {
          setClassList(classField.field_value);
        }
      }
    }
  };

  const handleSubmit = (values, { resetForm }, ind) => {
    if (values.id) {
      return updateDashboardSettings(values)
        .then((resp) => {
          // Handle successful response if needed
        })
        .catch((err) => {
          console.log(err);
          throw err; // Ensure the error is propagated
        });
    } else {
      return createDashboardSettings(values)
        .then((resp) => {
          if (resp.data?.createDashboardSettings?.success) {
            setForms(
              forms.map((form, index) => {
                if (index === ind) {
                  return {
                    ...form,
                    id: resp.data?.createDashboardSettings?.data?.id,
                  };
                }
                return form;
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          throw err; // Ensure the error is propagated
        });
    }
  };

  const handleDelete = () => {
    deleteDashboardSettings(settingsId)
      .then((resp) => {
        setForms(forms.filter((form) => form.id !== settingsId));
        setSettingsId(false);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteConfirm = (id) => {
    setIsOpen(true);
    setSettingsId(id);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSettingsId(false);
  };

  const redirectToPriviousPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    checkPermission('edit_dashboard') ? fetchData() : redirectToPriviousPage();
  }, []);

  return (
    <DashboardForm>
      <Box className="inner-content">
        <Box className="select-top">
          <Typography component="p">Select analytics</Typography>
          <FormControl className="select-box">
            <Select
              className="select"
              value={analytics}
              name="analytics"
              onChange={handleAnalyticsChange}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {analyticsList.map(({ hash_id, analytic_name }) => (
                <MenuItem key={hash_id} value={hash_id}>
                  {analytic_name}
                </MenuItem>
              ))}

              {/*               
              <MenuItem value={2}>Person Atributes 2</MenuItem>
              <MenuItem value={3}>Person Atributes 3</MenuItem> */}
            </Select>
          </FormControl>
        </Box>

        <DashboardSettingsForm
          forms={forms}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleDeleteConfirm={handleDeleteConfirm}
          classList={classList}
          statsTypes={statsTypes}
        />
        <DeleteDialog
          title={'Delete settings?'}
          message={'Are you sure you want to delete this settings?'}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onClose={handleClose}
          onDelete={handleDelete}
        />
        <Box
          className="add-more"
          sx={{
            padding: '10px 100px',
            '@media (max-width: 1460px)': {
              padding: '10px 80px',
            },
            '@media (max-width: 1199px)': {
              padding: '10px 55px',
            },
            '@media (max-width: 991px)': {
              padding: '10px 35px',
            },
            '@media (max-width: 499px)': {
              padding: '10px 20px',
            },
          }}
        >
          {analytics && <Button onClick={addForm}>Add More +</Button>}
        </Box>
      </Box>
    </DashboardForm>
  );
};

export default DashboardSettings;
