import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import BaseModal from '../Modal/BaseModal';
import ImageGalleryModal from '../ImageGallery';
import TimestampToDate from '../TimestampToDate';

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const AlertsDataTable = ({ rows, headCells, columns, analyticsSelection }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertClick, setAlertClick] = useState({});
  const rowsFetched = analyticsSelection ? rows : [];
  const visibleRows = rowsFetched;
  const imagePath = process.env.REACT_APP_IMAGE_URL;

  return (
    <TableContainer>
      {rowsFetched.length === 0 ? (
        <Typography
          variant="h4"
          component="div"
          style={{ textAlign: 'center' }}
        >
          {!analyticsSelection
            ? 'Please select an analytics'
            : 'No alerts found'}
        </Typography>
      ) : (
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'medium'}
          className="datatable_common"
        >
          <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
          <TableBody>
            {visibleRows.map((row) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row._id}
                  sx={{ cursor: 'pointer' }}
                >
                  {columns.map((col, index) => (
                    <TableCell
                      align={index === 0 ? 'left' : 'right'}
                      key={`${col}-${index}`}
                    >
                      {col === 'thumbnailUrl' ? (
                        <Box className="datatable_thumbnail">
                          <IconButton
                            onClick={() => {
                              setAlertClick(row);
                              setModalIsOpen(true);
                            }}
                          >
                            <img src={`${imagePath}/${row.thumb_url[0]}`} />
                          </IconButton>
                          {/* <IconButton>{row[col]}</IconButton> */}
                          {row.image_url.length > 1 && (
                            <Typography style={{ color: 'aquamarine' }}>{`+${
                              row.image_url.length - 1
                            }`}</Typography>
                          )}
                        </Box>
                      ) : col === 'cameraName' ? (
                        <Box>
                          <Typography>{row.camera_name}</Typography>
                        </Box>
                      ) : col === 'zoneName' ? (
                        <Box>
                          <Typography>{row.zone_name}</Typography>
                        </Box>
                      ) : col === 'timestamp' ? (
                        <Box>
                          <Typography>
                            <TimestampToDate timestamp={row[col]} />
                          </Typography>
                        </Box>
                      ) : (
                        row[col]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <ImageGalleryModal
        alert={alertClick}
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        images={alertClick?.image_url}
      />
    </TableContainer>
  );
};

export default AlertsDataTable;
