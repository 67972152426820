import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ModalLayoutStyle } from '../../Layouts/style';
import { AddIcon } from '../../Icons/SVGContainer';
import ApplicationService from '../../RestSevices/applicationService';
import useAuth from '../../Redux/Actions/authActions';

const validationSchema = Yup.object({
  port: Yup.number()
    .typeError('Port must be a valid number')
    .required('Port is required')
    .positive('Port must be a positive number'),
  ipAddress: Yup.string()
    .required('IP Address is required')
    .matches(
      /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$|^(?:[0-9a-fA-F]{1,4}:){2,7}[0-9a-fA-F]{1,4}$/,
      'Invalid IP Address'
    ),
  protocol: Yup.string().required('Protocol is required'),
  applicationFields: Yup.array().of(
    Yup.object().shape({
      field_value: Yup.string().required('Field Value is required'),
    })
  ),
});
const EditApplicationForm = ({ onClose, fetchData, applicationForEdit }) => {
  const url = applicationForEdit.server_url;
  const { logout } = useAuth();

  // Add a protocol if it's missing
  // const completeUrl = url.startsWith('http://') ? url : `http://${url}`;

  // Parse the URL
  const parsedUrl = new URL(url);
  // Extract components
  const protocol = parsedUrl.protocol.replace(':', ''); // Remove the colon
  const ipAddress = parsedUrl.hostname; // Extract the IP address
  const port = parsedUrl.port; // Extract the port

  const formik = useFormik({
    initialValues: {
      application_id: applicationForEdit.application_id || '',
      application_name: applicationForEdit.application_name || '',
      protocol: protocol || 'https',
      ipAddress: ipAddress || '',
      port: port || '',
      applicationFields:
        applicationForEdit?.applicationfields?.map((field) => ({
          field_name: field.field_name,
          field_value: field.field_value,
          field_type: field.field_type,
        })) || [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        ...values,
        server_url: `${values.protocol}://${values.ipAddress}:${values.port}`,
      };
      try {
        const result = await ApplicationService.updateApplication(
          applicationForEdit.hash_id,
          data
        );
        if (result.status === 200) {
          toast.success('Application updated successfully!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchData();
          onClose();
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          logout();
        } else {
          toast.error('Error in updating application', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    },
  });

  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <form onSubmit={formik.handleSubmit}>
          <Box className="form_field_area">
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Application Id</Typography>
                <TextField
                  name="application_id"
                  value={formik.values.application_id || ''}
                  onChange={formik.handleChange}
                  disabled
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Application Name</Typography>
                <TextField
                  name="application_name"
                  value={formik.values.application_name || ''}
                  onChange={formik.handleChange}
                  disabled
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Protocol</Typography>
                <Select
                  name="protocol"
                  value={formik.values.protocol || ''} // Default value or value from state
                  onChange={formik.handleChange}
                  error={
                    formik.touched.protocol && Boolean(formik.errors.protocol)
                  }
                  helperText={formik.touched.protocol && formik.errors.protocol}
                >
                  <MenuItem value="http">http://</MenuItem>
                  <MenuItem value="https">https://</MenuItem>
                  <MenuItem value="tcp">tcp://</MenuItem>
                </Select>
              </Box>
              <Box className="form_box_con">
                <Typography component="label">Ip</Typography>
                <TextField
                  name="ipAddress"
                  onChange={formik.handleChange}
                  value={formik.values.ipAddress || ''}
                  error={
                    formik.touched.ipAddress && Boolean(formik.errors.ipAddress)
                  }
                  helperText={
                    formik.touched.ipAddress && formik.errors.ipAddress
                  }
                />
              </Box>
              <Box className="form_box_con">
                <Typography component="label">Port</Typography>
                <TextField
                  name="port"
                  onChange={formik.handleChange}
                  value={formik.values.port || ''}
                  error={formik.touched.port && Boolean(formik.errors.port)}
                  helperText={formik.touched.port && formik.errors.port}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Typography component="label">Application fields</Typography>
            </Box>
            {formik.values.applicationFields.map((app_field, index) => (
              <Box className="form_box_hold" key={app_field.hash_id}>
                <Box className="form_box_con">
                  <Typography component="label">Field name</Typography>
                  <TextField
                    name={`applicationFields.${index}.field_name`}
                    value={app_field.field_name || ''}
                    disabled
                  />
                </Box>
                <Box className="form_box_con">
                  <Typography component="label">Value</Typography>
                  <TextField
                    name={`applicationFields.${index}.field_value`}
                    value={app_field.field_value || ''}
                    onChange={formik.handleChange}
                    disabled
                  />
                </Box>
                <Box className="form_box_con">
                  <Typography component="label">Datatype</Typography>
                  <TextField
                    name={`applicationFields.${index}.field_type`}
                    value={app_field.field_type || ''}
                    disabled
                  />
                </Box>
              </Box>
            ))}

            {/* <IconButton className="add_icon">
              <AddIcon /> Add Fields
            </IconButton> */}
          </Box>
          <Box className="add_btn">
            <Button className="cancel_btn" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Box>
        </form>
      </Box>
    </ModalLayoutStyle>
  );
};

export default EditApplicationForm;
