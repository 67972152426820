import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import AnalyticsService from '../../../RestSevices/analyticsService';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const MapView = (props) => {
  const [analytics, setAnalytics] = useState([]);
  const [analyticsList, setAnalyticsList] = useState([]);

  const handleAnalyticsChange = (event) => {
    setAnalytics(event.target.value);
  };

  const fetchData = async () => {
    await AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data || []);
        setAnalytics([resp.data[0]?.hash_id]);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Box>
            <Grid container spacing={3} justifyContent={'space-between'}>
              <Grid item lg={4} md={12}>
                <Typography variant="h4">Maps</Typography>
              </Grid>

              <Grid item lg={5} md={12}>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={12}>
                    <Select
                      sx={{ width: '100%' }}
                      value={analytics}
                      onChange={handleAnalyticsChange}
                      displayEmpty
                      multiple={true}
                    >
                      {analyticsList.map((analytic) => {
                        return (
                          <MenuItem
                            value={analytic.hash_id}
                            key={analytic.hash_id}
                          >
                            {analytic.analytic_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item lg={6} md={12}>
                    <Select
                      sx={{ width: '100%' }}
                      value={analytics}
                      onChange={handleAnalyticsChange}
                      displayEmpty
                      multiple={true}
                    >
                      {analyticsList.map((analytic) => {
                        return (
                          <MenuItem
                            value={analytic.hash_id}
                            key={analytic.hash_id}
                          >
                            {analytic.analytic_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
      />

      <CardContent>
        <Map
          google={props.google}
          zoom={10}
          initialCenter={{ lat: 37.774929, lng: -122.419418 }} // Initial center coordinates
        >
          {/* Custom markers */}
          <Marker position={{ lat: 37.774929, lng: -122.419418 }} />
          {/* Add more markers as needed */}
        </Map>
      </CardContent>
    </Card>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY, // Replace with your actual API key
})(MapView);
