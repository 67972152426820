import React from 'react';
import { Helmet } from 'react-helmet';
import { ForgotPasswordStyle } from '../../Layouts/style';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { RightArrow } from '../../Icons/SVGContainer';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPasswordValidationSchema } from '../../Utils/validators';
import { Formik } from 'formik';
import useAuth from '../../Redux/Actions/authActions';
const ForgotPassword = () => {
  const { forgot_password } = useAuth();

  const navigate = useNavigate();
  const forgotPasswordState = {
    email: '',
  };
  const handleSubmit = async (values, { resetForm }) => {
    // Handle form submission logic here
    // login({ body: values });
    const resp = await forgot_password(values.email);
    if (resp?.data?.tenantForgotPassword?.success) {
      navigate('/check-email', { replace: true });
    }
    // Reset the form after submission
    // resetForm();
  };
  return (
    <ForgotPasswordStyle>
      <Helmet>
        <title>Mirasys: Forgot-Password</title>
      </Helmet>
      <Box className="forgot_pass_area">
        <Box className="forgot_box_con">
          <Box className="forgot_heading">
            <img src="/images/logo.svg" alt="" />
            <Typography component="h2">Forgot Password?</Typography>
            <Typography>
              Don’t worry, we’ll send you reset instructions.{' '}
            </Typography>
          </Box>
          <Formik
            initialValues={forgotPasswordState}
            validationSchema={forgotPasswordValidationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Box className="form_box">
                  <Typography component="label">Email id</Typography>
                  <FormControl fullWidth>
                    <TextField
                      name="email"
                      type="email"
                      placeholder="info@gmail.com"
                      onChange={props.handleChange}
                      error={props.touched.email && !!props.errors.email}
                      helperText={props.touched.email ? props.errors.email : ''}
                    />
                  </FormControl>
                </Box>
                <Box className="form_box">
                  <Button className="submitBtn  bg-primary-hover" type="submit">
                    Reset password
                    <RightArrow />
                  </Button>
                </Box>
                <Box className="forgot_botinfo">
                  <Typography>
                    Back to{' '}
                    <Link className="color-primary-hover" to="/login">
                      Login
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </ForgotPasswordStyle>
  );
};

export default ForgotPassword;
