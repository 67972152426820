import axios from '../Utils/api';

const VideoUploadService = {
  uploadVideo: (formData,setUploadProgress) => {
    return axios.post(
      '/videouploads/create',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data' // Set the content type for file upload
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      },
    );
  },
};

export default VideoUploadService;
