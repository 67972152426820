import { apolloClient } from '../apolloClient';
import { GET_ALL_VIDEOS } from '../videoUpload';

export const getAllVideos = async ({ sortBy, sortOrder, page }) => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_VIDEOS,
      variables: { sortBy, sortOrder, page },
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};
