import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  user: null,
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },
    updateUser: (state, action) => {
      state.user = action.payload.user;
    },
    logout: (state, action) => {
      state.token = '';
      state.user = null;
      state.isAuthenticated = null;
    },
  },
});

export const { actions } = authSlice;

export const selectToken = (state) => state.auth.token;
export const selectUser = (state) => state.auth.user;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

export default authSlice.reducer;
