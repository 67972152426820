import React, { useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ActiveIcon,
  DeleteIcon,
  EditIcon,
  OpenBorderEyeIcon,
  SettingIcon,
} from '../../Icons/SVGContainer';

function EnhancedTableHead(props) {
  const { headCells, type, handleAllCheckboxClick, checkIfAllSelected } = props;
  let actionType = (() => {
    switch (type) {
      case 'camera':
        return 'camera_actions';
      case 'application':
        return 'application';
      default:
        return 'analytic'; // Add a default case if needed
    }
  })();

  return (
    <TableHead>
      <TableRow className="table_head">
        <TableCell key={0} align={'left'} padding={'none'}>
          {(() => {
            switch (type) {
              case 'camera':
                return 'Camera Name';
              case 'application':
                return 'Application Name';
              default:
                return 'Analytic Name'; // Add a default case if needed
            }
          })()}
        </TableCell>
        {type == 'camera' && (
          <TableCell key={0} align={'left'} padding={'none'}>
            Created By
          </TableCell>
        )}
        {headCells.map((headCell) => {
          return (
            headCell.type == actionType && (
              <TableCell key={headCell.id} align={'left'} padding={'none'}>
                <Checkbox
                  onClick={() => handleAllCheckboxClick(headCell.id, type)}
                  checked={checkIfAllSelected(headCell.id, type)}
                />
                {headCell.title}
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const PermissionDataTable = ({
  rows = [],
  columns,
  onClick,
  handleCheckboxClick,
  handleAllCheckboxClick,
  checkIfChecked,
  checkIfAllSelected,
  type,
  objectType,
}) => {
  const visibleRows = rows;

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={'medium'}
        className="datatable_common"
        stickyHeader
      >
        <EnhancedTableHead
          rowCount={columns.length}
          headCells={columns}
          type={objectType}
          handleAllCheckboxClick={handleAllCheckboxClick}
          checkIfAllSelected={checkIfAllSelected}
        />
        <TableBody
          sx={{
            '.MuiTableCell-root': {
              wordBreak: 'break-word',
            },
          }}
        >
          {visibleRows.length
            ? visibleRows.map((row) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell align={'left'} key={row.id + 1}>
                      {(() => {
                        switch (objectType) {
                          case 'camera':
                            return row.camera_name;
                          case 'application':
                            return row.application_name;
                          default:
                            return row.analytic_name; // Add a default case if needed
                        }
                      })()}
                    </TableCell>
                    {objectType == 'camera' && (
                      <TableCell align={'left'} key={row.id}>
                        {row.created_by_name}
                      </TableCell>
                    )}
                    {columns.map(
                      (col, index) =>
                        col.type == type && (
                          <TableCell align="right" key={col.id}>
                            <Checkbox
                              onClick={() =>
                                handleCheckboxClick(col.id, row.id, objectType)
                              }
                              checked={checkIfChecked({
                                permissionId: col.id,
                                objectId: row.id,
                                objectType: objectType,
                              })}
                            />
                          </TableCell>
                        )
                    )}
                  </TableRow>
                );
              })
            : `No ${objectType} found`}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PermissionDataTable;
