import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const TableBox = styled(Box)(({ theme }) => ({
   position: 'relative',
   width: '100%',
   display: 'flex',
   justifyContent: 'space-between',
   marginBottom: '25px',
   flexWrap: 'wrap',

   '.object_table_head_left': {
        width: 'max-content',
   },

   '.object_table_head_right': {
        width: 'max-content',
   },

   '@media (max-width: 1440px)': {
        '.object_table_head_right': {
            width: '100%',
            marginTop: '5px',
            flexWrap: 'wrap',

            '.MuiTextField-root.search': {
                width: '25rem',
                marginRight: 'auto'
            }
        },
   },

   '@media (max-width: 1250px)': {
        '.object_table_head_right': {

            '.MuiTextField-root.search': {
                width: '100%',
            }
        },
   },

   '@media (max-width: 850px)': {
        '.object_table_head_right': {
            justifyContent: 'space-between',

            '.MuiStack-root ': {
                width: '48.5%',

                '.MuiFormControl-root':{
                    minWidth: '0'
                },
                'input': {
                    width: '100%'
                }
            },

            'button':{
                marginTop: '10px',
                marginLeft: 'auto'
            }
        },
   },

   '@media (max-width: 575px)': {
        '.object_table_head_right': {

            '.MuiStack-root ': {
                width: '100%',

                'input': {
                    width: '100%'
                }
            }
        },
   }
}))