import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { DashboardStyle } from '../../../Layouts/style';
import {
  MaximizeIcon,
  MaximumPeopleIcon,
  MinimizeIcon,
  RefreshIcon,
  TimeDurationIcon,
  TotalPeopleIcon,
} from '../../../Icons/SVGContainer';
import BarChart from '../../../Components/Chart/BarChart';
import AnalyticsService from '../../../RestSevices/analyticsService';
import { getAllSettingsByAnalyticIds } from '../../../graphQL/Dashboard/query';
import AlertsService from '../../../RestSevices/alertService';
import ChartModal from '../../../Components/Modal/ChartModal';
import {
  dateRange,
  formatBarChart,
  formatNochart,
  formatLineChart,
  formatPieChart,
  getRandomColor,
} from '../../../Utils/dashboardHelper';
import LineChart from '../../../Components/Chart/LineChart';
import PieChart from '../../../Components/Chart/PieChart';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../Redux/Actions/authActions';
import { toast } from 'react-toastify';
import DashboardService from '../../../RestSevices/dashboardService';
import { checkPermission } from '../../../Utils/checkPermission';

const DashboardView = () => {
  const [analytics, setAnalytics] = useState([]);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  const [alertsList, setAlertsList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [range, setRange] = useState('24hours');
  const [selectedChartData, setSelectedChartData] = useState({});
  const [openChartModal, setOpenChartModal] = useState(false);
  const { logout } = useAuth();

  const navigate = useNavigate();

  const handleAnalyticsChange = (event) => {
    setAnalytics(event.target.value);
  };

  const handleRangeChange = (event) => {
    setRange(event.target.value);
  };

  const goToSettings = () => {
    navigate('/dashboard-settings');
  };

  const handleMinimizeChart = (id) => {
    const index = dataList.findIndex((data) => data.id == id);
    const newData = { ...dataList[index] };
    newData.minimizeChart = true;
    dataList[index] = newData; // Update dataList with modified data
    setDataList([...dataList]); // Update state
  };
  const handleMaximizeChart = (id) => {
    const index = dataList.findIndex((data) => data.id == id);
    const newData = { ...dataList[index] };
    newData.minimizeChart = false;
    dataList[index] = newData; // Update dataList with modified data
    setDataList([...dataList]); // Update state
  };

  const handleOpenChartModal = (type, data) => {
    setSelectedChartData({ type, data });
    setOpenChartModal(true);
  };

  const fetchData = async () => {
    await AnalyticsService.getAllAnalyticsList()
      .then((resp) => {
        setAnalyticsList(resp.data || []);
        setAnalytics([resp.data[0]?.hash_id]);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        console.log(err);
      });
  };
  //test
  const fetchAllSettings = async () => {
    try {
      const settingsresp = await getAllSettingsByAnalyticIds(analytics);
      if ('errors' in settingsresp) {
        if (settingsresp?.errors[0].message == 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
      const settingsData =
        settingsresp?.data?.getAllDashboardSettingsByAnalyticIds?.dashboardSettings?.map(
          (forms) => {
            return {
              ...forms,
              chart_parameters: forms.chart_parameters
                ? forms.chart_parameters.split(',')
                : [],
            };
          }
        );
      const modifiedSettingsList = settingsData.map((settings) => {
        return { ...settings, isLoading: true };
      });
      setSettingsList(modifiedSettingsList || []);
      setDataList(modifiedSettingsList || []);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAlerts = async () => {
    try {
      let selectedAnalyctis;
      analyticsList.forEach((data) => {
        if (data.hash_id == analytics) {
          selectedAnalyctis = data.analytic_name;
        }
        return null;
      });
      const alertsListResp = await AlertsService.getDashboardAlerts({
        analytics: selectedAnalyctis,
        range,
      });
      setAlertsList(alertsListResp.data);
    } catch (error) {
      if (error.response?.data?.error == 'Authentication failed') {
        logout();
        toast.error('Session Expired!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.log(error);
    }
  };

  useEffect(() => {
    formatChartData();
  }, [settingsList, alertsList]);

  const formatChartData = async () => {
    for (let i = 0; i < dataList.length; i++) {
      const data = dataList[i];
      if (data.isLoading === true) {
        const resp = await DashboardService.getChartDataById(data.id, range);
        const newData = { ...data };
        // Modify the clone
        newData.data = resp;
        newData.isLoading = false;
        dataList[i] = newData; // Update dataList with modified data
        setDataList([...dataList]); // Update state
      }
    }
  };

  const refreshChart = async (data) => {
    for (let i = 0; i < dataList.length; i++) {
      if (dataList[i].id == data.id) {
        const newData = { ...dataList[i] };
        newData.isLoading = true;
        dataList[i] = newData; // Update dataList with modified data
        setDataList([...dataList]); // Update state
        const resp = await DashboardService.getChartDataById(
          data.id,
          range,
          true
        );
        newData.data = resp;
        newData.isLoading = false;
        dataList[i] = newData; // Update dataList with modified data
        setDataList([...dataList]); // Update state
      }
      // Modify the clone
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (analytics.length) {
      fetchAllSettings();
      fetchAlerts();
    } else {
      setDataList([]);
    }
  }, [analytics]);

  useEffect(() => {
    if (analytics.length) {
      fetchAllSettings();
    }
  }, [range]);

  return (
    <DashboardStyle>
      <Box className="dashboard_area">
        <Box className="dashboard_left">
          <Box className="dashboard_heading">
            <Typography component="h2">Dashboard</Typography>
            <Box className="dash_select_area">
              <Select
                value={analytics}
                onChange={handleAnalyticsChange}
                displayEmpty
                multiple={true}
              >
                {analyticsList.map((analytic) => {
                  return (
                    <MenuItem value={analytic.hash_id} key={analytic.hash_id}>
                      {analytic.analytic_name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select value={range} onChange={handleRangeChange} displayEmpty>
                {dateRange.map((data) => {
                  return <MenuItem value={data.value} key={data.value}>{data.label}</MenuItem>;
                })}
              </Select>
              {checkPermission('edit_dashboard') && (
                <IconButton onClick={goToSettings}>
                  <img src="/images/settings_icon.svg" alt="" />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box className="dashboard_top_info">
            {dataList.map((data) => {
              if (data.chart === 'no_chart' && data.isLoading === false) {
                return (
                  <Box className="dashboard_top_info_box" key={data.name}>
                    <Box>
                      <Typography>{data.name}</Typography>
                      <Typography component="h2">{data.data?.data}</Typography>
                    </Box>
                    <Box className="dashboard_top_info_icon">
                      {data.statsType?.title === 'Average' ? (
                        <TimeDurationIcon />
                      ) : (
                        <TotalPeopleIcon />
                      )}
                    </Box>
                  </Box>
                );
              } else {
                return null;
              }
            })}
          </Box>
          <Grid container spacing={2} className="dashboard_chart_area">
            {dataList.map((data) => (
              <React.Fragment key={data.id}>
                {data.isLoading ? (
                  // Display loader if data is loading
                  <Grid item lg={6} md={12}>
                    <div className="dashboard_chart_con">
                      <CircularProgress />
                    </div>
                  </Grid>
                ) : (
                  <React.Fragment>
                    {data.chart === 'bar_chart' && (
                      <Grid item lg={6} md={12}>
                        <div className="dashboard_chart_con">
                          <Box className="dashboard_chart_head">
                            <Typography component="h3">
                              {data.name.toUpperCase()}
                              <Box className="dashboard_chart_head_btn">
                                <Tooltip
                                  title="Refresh"
                                  onClick={() => refreshChart(data)}
                                >
                                  <IconButton>
                                    <RefreshIcon />
                                  </IconButton>
                                </Tooltip>
                                {data.minimizeChart ? (
                                  <Tooltip
                                    title="Maximize Chart"
                                    onClick={() => handleMaximizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MaximizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Minimize Chart"
                                    onClick={() => handleMinimizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MinimizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Expand Chart">
                                  <IconButton
                                    onClick={() =>
                                      handleOpenChartModal('bar', data)
                                    }
                                  >
                                    <img src="/images/expand_icon.svg" alt="" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          </Box>
                          <Box
                            className={
                              data.minimizeChart
                                ? 'chart_con hide'
                                : 'chart_con'
                            }
                          >
                            <BarChart configData={data.data} type="bar" />
                          </Box>
                        </div>
                      </Grid>
                    )}
                    {data.chart === 'line_chart' && (
                      <Grid item lg={6} md={12}>
                        <div className="dashboard_chart_con">
                          <Box className="dashboard_chart_head">
                            <Typography component="h3">
                              {data.name.toUpperCase()}
                              <Box className="dashboard_chart_head_btn">
                                <Tooltip
                                  title="Refresh"
                                  onClick={() => refreshChart(data)}
                                >
                                  <IconButton>
                                    <RefreshIcon />
                                  </IconButton>
                                </Tooltip>
                                {data.minimizeChart ? (
                                  <Tooltip
                                    title="Maximize Chart"
                                    onClick={() => handleMaximizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MaximizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Minimize Chart"
                                    onClick={() => handleMinimizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MinimizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Expand Chart">
                                  <IconButton
                                    onClick={() =>
                                      handleOpenChartModal('line', data)
                                    }
                                  >
                                    <img src="/images/expand_icon.svg" alt="" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          </Box>
                          <Box
                            className={
                              data.minimizeChart
                                ? 'chart_con hide'
                                : 'chart_con'
                            }
                          >
                            <LineChart configData={data.data} type="line" />
                          </Box>
                        </div>
                      </Grid>
                    )}
                    {data.chart === 'pie_chart' && (
                      <Grid item lg={6} md={12}>
                        <div className="dashboard_chart_con">
                          <Box className="dashboard_chart_head">
                            <Typography component="h3">
                              {data.name.toUpperCase()}
                              <Box className="dashboard_chart_head_btn">
                                <Tooltip
                                  title="Refresh"
                                  onClick={() => refreshChart(data)}
                                >
                                  <IconButton>
                                    <RefreshIcon />
                                  </IconButton>
                                </Tooltip>
                                {data.minimizeChart ? (
                                  <Tooltip
                                    title="Maximize Chart"
                                    onClick={() => handleMaximizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MaximizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Minimize Chart"
                                    onClick={() => handleMinimizeChart(data.id)}
                                  >
                                    <IconButton>
                                      <MinimizeIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Expand Chart">
                                  <IconButton
                                    onClick={() =>
                                      handleOpenChartModal('pie', data)
                                    }
                                  >
                                    <img src="/images/expand_icon.svg" alt="" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Typography>
                          </Box>
                          <Box
                            className={
                              data.minimizeChart
                                ? 'chart_con hide'
                                : 'chart_con'
                            }
                          >
                            <PieChart configData={data.data} type="pie" />
                          </Box>
                        </div>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </Grid>
          <Box className="dashboard_camera_pic">
            <Box>
              <Typography component="h3">Camera pictures</Typography>
              <img src="/images/cam_pic.png" alt="" />
            </Box>
            <Box>
              <Typography component="h3">Camera paths</Typography>
              <img src="/images/cam_pic.png" alt="" />
            </Box>
          </Box>
        </Box>
        <Box className="dashboard_right">
          <Typography component="h3">Alerts</Typography>
          <Box component="ul">
            <Typography component="li">
              Alene women detection at 6.30pm
            </Typography>
            <Typography component="li">
              Stone pelting found at 6.30pm
            </Typography>
            <Typography component="li">Women assult found at 6.30pm</Typography>
            <Typography component="li">
              Alene women detection at 6.30pm
            </Typography>
            <Typography component="li">
              Stone pelting found at 6.30pm
            </Typography>
            <Typography component="li">Women assult found at 6.30pm</Typography>
          </Box>
        </Box>
        <ChartModal
          open={openChartModal}
          onClose={() => {
            setOpenChartModal(false);
            setSelectedChartData({});
          }}
          data={selectedChartData.data?.data}
          type={selectedChartData.type}
          title={selectedChartData.data?.name.toUpperCase()}
          sx={{ minWidth: '1000px !important' }}
        >
          <Box>chart here</Box>
        </ChartModal>
      </Box>
    </DashboardStyle>
  );
};

export default DashboardView;
