import axios from '../Utils/api';

const ZoneService = {
  createZone: (formData,camerahashid) => {
    return axios.post('/cameras/create-camera-zone', formData, {
      headers: {
        camerahashid: camerahashid,
      },
    });
  },

  getZones: (camerahashid) => {
    return axios.get('/cameras/get-camera-configuration-details', {
      headers: {
        camerahashid: camerahashid,
      },
    });
  },

  updateZone: (formData,camerahashid) => {
    return axios.put('/cameras/update-camera-zone', formData, {
      headers: {
        camerahashid: camerahashid,
      },
    });
  },

  deleteZone: (hashId,camerahashid) => {
    return axios.post('/cameras/delete-camera-zone',{},{
      headers: {
        camerahashid: camerahashid,
        zonehashid: hashId,
      },
    });
  },


};

export default ZoneService;
