import { gql } from '@apollo/client';

export const GET_ALL_PERMISSIONS = gql`
  query GetAllPermissions {
    getAllPermissions {
      permissions {
        id
        active
        title
        slug
        type
        module
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PERMISSION = gql`
  query GetPermission {
    getPermission {
      id
      active
      slug
      title
      type
      module
      createdAt
      updatedAt
    }
  }
`;

export const ASSIGN_PERMISSION = gql`
  mutation AssignPermissions(
    $roleId: ID!
    $permissions: [PermissionWithObjectInput]
  ) {
    assignPermissions(roleId: $roleId, permissions: $permissions) {
      success
    }
  }
`;

export const PERMISSION_WITH_OBJECT_INPUT = gql`
  input PermissionWithObjectInput {
    permissionId: ID
    resourceId: ID
    resourceType: String
  }
`;
