import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const StyledHeaderParent = styled(Box)(({ _theme }) => ({
    marginBottom: '2rem',
    justifyContent: 'space-between',

    '& , .search-part': {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
    },

    '@media(max-width: 1199px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    }
}));

export const StyledHeader = styled(Box)(({ _theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridGap: '1rem',
    alignItems: 'center',
    maxWidth: '40rem',

    '.MuiFormControl-root': {
        minWidth: 'unset !important'
    },

    '@media(max-width: 991px)': {
        gridTemplateColumns: '1fr auto auto',

        '.MuiStack-root:first-child': {
            gridColumn: '1/4'
        }
    }
}));