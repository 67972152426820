import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { DashboardIcon, HamburgerIcon } from '../Icons/SVGContainer';
import { SidebarStyle } from '../Layouts/style';
import { Link } from 'react-router-dom';
import Menu from './Menu';

const Sidebar = () => {
  const [sidebarOpen, setsidebarOpen] = useState(true);
  const handleSidebar = () => {
    setsidebarOpen(!sidebarOpen);
  };
  return (
    <SidebarStyle>
      <IconButton className="menu_toggle_btn" onClick={handleSidebar}>
        <HamburgerIcon />
      </IconButton>
      <Box className={sidebarOpen ? 'sidebar' : 'sidebar sidebar_open'}>
        <Box component="ul">
          <Menu />
        </Box>
      </Box>
    </SidebarStyle>
  );
};

export default Sidebar;
