import React, { useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ActiveIcon,
  DeleteIcon,
  EditIcon,
  OpenBorderEyeIcon,
  SettingIcon,
} from '../../Icons/SVGContainer';

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const BusinessDataTable = ({ rows, headCells, columns, onClick }) => {
  const visibleRows = rows;

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={'medium'}
        className="datatable_common"
      >
        <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
        <TableBody>
          {visibleRows.map((row) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: 'pointer' }}
              >
                {columns.map((col, index) => {
                  let cellContent;

                  if (col === 'fullName') {
                    cellContent = (
                      <Box className="task_name">
                        <img src="/images/admin_pic.png" />
                        <Typography component="span">{`${row.firstname} ${row.lastname}`}</Typography>
                      </Box>
                    );
                  } else if (col === 'status') {
                    cellContent = (
                      <Chip
                        icon={<ActiveIcon />}
                        label="Active"
                        className="active_data"
                      />
                    );
                  } else if (col === 'action') {
                    cellContent = (
                      <Box className="action_icon">
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton onClick={onClick}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    );
                  } else {
                    cellContent = row[col];
                  }

                  return (
                    <TableCell align={index === 0 ? 'left' : 'right'} key={col}>
                      {cellContent}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BusinessDataTable;
