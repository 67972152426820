import { Box, Typography } from '@mui/material';
import { ModalLayoutStyle } from '../../../Layouts/style';

const CameraDetails = ({ camera }) => {
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Box className="camera_info_area">
          <Box className="camera_info">
            <Typography component="h4">Hash id</Typography>
            <Typography component="h3">{camera.hash_id}</Typography>
          </Box>
          <Box className="camera_info">
            <Typography component="h4">Camera id</Typography>
            <Typography component="h3">{camera.camera_id}</Typography>
          </Box>
          <Box className="camera_info">
            <Typography component="h4">Camera name</Typography>
            <Typography component="h3">{camera.camera_name}</Typography>
          </Box>
          <Box className="camera_info">
            <Typography component="h4">Creation date</Typography>
            <Typography component="h3">
              {new Date(Date.parse(camera.creation_date)).toDateString()}
            </Typography>
          </Box>
          <Box className="camera_info">
            <Typography component="h4">FPS</Typography>
            <Typography component="h3">{camera.fps}</Typography>
          </Box>
          <Box className="camera_info">
            <Typography component="h4">RTSP Url</Typography>
            <Typography component="h3">{camera.rstp_url}</Typography>
          </Box>
          <Box className="camera_info">
            <Typography component="h4">Location</Typography>
            <Typography component="h3">{camera.location}</Typography>
          </Box>
        </Box>
        <Box className="add_btn"></Box>
      </Box>
    </ModalLayoutStyle>
  );
};

export default CameraDetails;
