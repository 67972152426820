import React, { useState } from 'react';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ActiveIcon,
  DeleteIcon,
  EditIcon,
  InActiveIcon,
} from '../../Icons/SVGContainer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteTenantUser } from '../../graphQL/User/mutation';
import { checkUserManagementPermission } from '../../Utils/checkPermission';

function DeleteUserDialog({ isOpen, onClose, onDelete }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete User?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ color: '#fff' }}>
          Are you sure you want to delete this user?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TableCellContent({ col, row, openForm, setUserToDelete, setIsOpen }) {
  if (col === 'fullName') {
    return (
      <Box className="task_name">
        <Typography component="span">{`${row.firstname} ${row.lastname}`}</Typography>
      </Box>
    );
  } else if (col === 'roles') {
    return row?.userRoles?.map((role, index) => (
      <Typography key={role?.role?.title} component="span">
        {role?.role?.title ? ` ${role?.role?.title}` : ''}
        {row?.userRoles.length - 1 !== index ? ',' : ''}
      </Typography>
    ));
  } else if (col === 'status') {
    return row.status === 'Active' ? (
      <Chip icon={<ActiveIcon />} label={row.status} className="active_data" />
    ) : (
      <Chip
        icon={<InActiveIcon />}
        label={row.status}
        className="inactive_data"
      />
    );
  } else if (col === 'action') {
    return (
      <Box className="action_icon">
        {checkUserManagementPermission('delete_user', row) &&
          row.userType !== 'Admin' && (
            <IconButton
              onClick={() => {
                setUserToDelete(row.id);
                setIsOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        {checkUserManagementPermission('edit_user', row) &&
          row.userType !== 'Admin' && (
            <IconButton onClick={() => openForm(row)}>
              <EditIcon />
            </IconButton>
          )}
      </Box>
    );
  } else if (col === 'createdByUser') {
    return (
      <Box className="task_name">
        {row.createdByUser && (
          <Typography component="span">{`${row.createdByUser?.firstname} ${row.createdByUser?.lastname}`}</Typography>
        )}
      </Box>
    );
  } else {
    return row[col];
  }
}

const UserDataTable = ({ rows, headCells, columns, openForm, fetchUsers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState('');

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = async () => {
    const resp = await deleteTenantUser(userToDelete);
    if (resp?.data?.deleteTenantUser) {
      fetchUsers();
      setIsOpen(false);
    }
  };

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={'medium'}
        className="datatable_common"
      >
        <EnhancedTableHead headCells={headCells} />
        <TableBody>
          {rows.map((row) => (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              sx={{ cursor: 'pointer' }}
            >
              {columns.map((col, index) => (
                <TableCell align={index === 0 ? 'left' : 'right'} key={col}>
                  <TableCellContent
                    col={col}
                    row={row}
                    openForm={openForm}
                    setUserToDelete={setUserToDelete}
                    setIsOpen={setIsOpen}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DeleteUserDialog
        isOpen={isOpen}
        onClose={handleClose}
        onDelete={handleDelete}
      />
    </TableContainer>
  );
};

export default UserDataTable;
