import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = ({ configData = [], type }) => {

  // Check if type is undefined and set a default value
  const chartType = type || 'line';

  // Your data for the line chart
  const chartData = {
    chart: {
      height: 350,
      type: chartType, // Use the chartType variable here
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: configData.data.map((val) => {
      return {
        name: val.series_name || '',
        data: val.data.map((val) => val.data),
        color: val.color,
      };
    }),
    stroke: {
      width: [4, 4],
      colors: ['#b8b8b8'],
    },
    plotOptions: {
      bar: {
        columnWidth: '20%',
      },
    },
    xaxis: {
      categories: configData.data[0].data.map((val) => {
        return val.x_axis;
      }),
      labels: {
        style: {
          colors: configData.data[0].data.map((val) => '#fff'),
        },
      },
      title: {
        text: configData.data[0]?.data[0]?.x_axis_label || '',
        style: {
          color: '#fff',
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        labels: {
          style: {
            colors: '#fff',
          },
        },
        title: {
          text: configData.data[0].data[0]?.statsType || '',
          style: {
            color: '#fff',
          },
        },
        tickAmount: 5,
      },
    ],
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      labels: {
        colors: '#fff',
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
    },
    fill: {
      opacity: [1, 0.8],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
  };
  return (
    <div>
      <Chart
        options={chartData}
        series={chartData.series}
        type={chartType}
        height="300px"
      />
    </div>
  );
};

export default LineChart;
