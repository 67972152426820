import { apolloClient } from '../apolloClient';
import { GET_ALL_PERMISSIONS } from '../permission';
import { GET_ROLES, GET_ROLE, GET_USERS_BY_ROLE, GET_ALL_ROLES } from '../role';

export const getRoles = async ({ sortBy, sortOrder, search, page }) => {
  try {
    const response = await apolloClient.query({
      query: GET_ROLES,
      variables: { sortBy, sortOrder, search, page },
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const getAllRoles = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_ROLES,
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const getRole = async (id) => {
  try {
    const response = await apolloClient.query({
      query: GET_ROLE,
      variables: { getRoleId: id },
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const getUsersByRole = async (id) => {
  try {
    const response = await apolloClient.query({
      query: GET_USERS_BY_ROLE,
      variables: { getUsersByRoleId: id },
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const getAllPermissions = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_PERMISSIONS,
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};
