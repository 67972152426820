import { Box, Typography } from '@mui/material';
import { ModalLayoutStyle } from '../../../Layouts/style';

const CameraPreview = () => {
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Box className="camera_info_area">
          <Box className="camera_preview_area">
            <Box>
              <img src="/images/camera_preview_pic.png" alt="" />
            </Box>
            <Box className="preview_info">
              <Box className="camera_info">
                <Typography component="h4">Hash id</Typography>
                <Typography component="h3">aaa</Typography>
              </Box>
              <Box className="camera_info">
                <Typography component="h4">Camera id</Typography>
                <Typography component="h3">aaa</Typography>
              </Box>
              <Box className="camera_info">
                <Typography component="h4">Camera name</Typography>
                <Typography component="h3">aaa</Typography>
              </Box>
              <Box className="camera_info">
                <Typography component="h4">Creation date</Typography>
                <Typography component="h3">aaa</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="add_btn"></Box>
      </Box>
    </ModalLayoutStyle>
  );
};

export default CameraPreview;
