import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';
import { Formik } from 'formik';
import { roleValidationSchema } from '../../Utils/validators';
import { createRole, updateRole } from '../../graphQL/Role/mutation';
import { getRole } from '../../graphQL/Role/query';

const AddRoleForm = ({ onClose, roleId, fetchRoles }) => {
  const [roleState, setRoleState] = useState({
    title: '',
    status: 1,
  });

  const fetchData = async () => {
    try {
      if (roleId) {
        const resp = await getRole(roleId);
        setRoleState({
          title: resp.data.getRole.title,
          status: resp.data.getRole.active,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (roleId) {
      fetchData();
    }
  }, [roleId]);
  const handleSubmit = (values, { resetForm }) => {
    // If no errors, handle form submission here
    if (roleId) {
      updateRole({
        id: roleId,
        title: values.title.trim(),
        status: values.status,
      })
        .then(() => {
          onClose();
          resetForm();
          fetchRoles();
        })
        .catch((error) => {});
    } else {
      createRole({
        title: values.title.trim(),
        status: values.status,
      })
        .then((data) => {
          fetchRoles();
          onClose();
          resetForm();
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Formik
          initialValues={roleState}
          validationSchema={roleValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Box className="form_field_area ">
                <Box className="form_box_hold">
                  <Box className="form_box_con full_width">
                    <Typography component="label">Role Name</Typography>
                    <TextField
                      name="title"
                      type="text"
                      value={props.values.title}
                      onChange={props.handleChange}
                      error={props.touched.title && !!props.errors.title}
                      helperText={props.touched.title ? props.errors.title : ''}
                    />
                  </Box>
                </Box>
                <Box className="form_box_con">
                  <Typography component="label">Status</Typography>
                  <Select
                    name="status"
                    value={props.values.status}
                    onChange={props.handleChange}
                    displayEmpty
                    error={props.touched.status && !!props.errors.status}
                    helperText={props.touched.status ? props.errors.status : ''}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Inactive</MenuItem>
                  </Select>
                </Box>
              </Box>
              <Box className="add_btn">
                <Button className="cancel_btn" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit">Create</Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </ModalLayoutStyle>
  );
};

export default AddRoleForm;
