import { Box, Typography } from '@mui/material';
import { ModalLayoutStyle } from '../../../Layouts/style';
import AriaChart from '../../../Components/Chart/AriaChart';

const CameraAnalytics = () => {
  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <Box className="camera_info_area">
          <AriaChart />
        </Box>
        <Box className="add_btn"></Box>
      </Box>
    </ModalLayoutStyle>
  );
};

export default CameraAnalytics;
