import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUser {
    getUsers {
      email
    }
  }
`;

export const TENANT_LOGIN_MUTATION = gql`
  mutation TenantUserLogin($email: String!, $password: String!) {
    tenantUserLogin(email: $email, password: $password) {
      user {
        email
        firstname
        lastname
        id
        status
        username
        userType
        hasChangedPassword
        userRoles {
          id
          role {
            id
            active
            title
            rolePermissions {
              rolePermissionObjects {
                id
                objectId
                objectType
                roleId
                rolePermissionId
              }
              permission {
                id
                module
                slug
                title
                type
                active
              }
            }
          }
        }
      }
      token
    }
  }
`;

export const TENANT_LOGOUT_MUTATION = gql`
  mutation Mutation {
    tenantUserLogout
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation Mutation($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const TENANT_FORGOT_PASSWORD_MUTATION = gql`
  mutation Mutation($email: String!) {
    tenantForgotPassword(email: $email) {
      success
    }
  }
`;

export const TENANT_RESET_PASSWORD_MUTATION = gql`
  mutation Mutation(
    $passwordResetToken: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetTenantUserPassword(
      passwordResetToken: $passwordResetToken
      password: $password
      confirmPassword: $confirmPassword
    )
  }
`;

export const TENANT_CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangeTenantPassword(
    $old_password: String!
    $new_password: String!
    $confirm_password: String!
  ) {
    changeTenantPassword(
      old_password: $old_password
      new_password: $new_password
      confirm_password: $confirm_password
    ) {
      user {
        email
        firstname
        lastname
        id
        status
        username
        userType
        hasChangedPassword
        userRoles {
          id
          role {
            id
            active
            title
            rolePermissions {
              rolePermissionObjects {
                id
                objectId
                objectType
                roleId
                rolePermissionId
              }
              permission {
                id
                module
                slug
                title
                type
                active
              }
            }
          }
        }
      }
    }
  }
`;
