import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

function FilterSidebar() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div>
      <IconButton
        onClick={toggleDrawer}
        style={{ position: 'fixed', top: '10px', left: '10px' }}
      >
        <FilterListIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <Box
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
          style={{ width: 250 }}
        >
          <List>
            <ListItem>
              <ListItemText primary="Filters" />
            </ListItem>
            {/* Add your filter controls here */}
            <ListItem>
              <input
                type="text"
                value={''}
                onChange={''}
                placeholder="Search..."
              />
            </ListItem>
            <ListItem>
              <button>Reset Filters</button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default FilterSidebar;
