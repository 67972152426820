import React, { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import BaseModal from './Modal/BaseModal';

const ImageGallery = ({ alert, open, onClose, images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const imagePath = process.env.REACT_APP_IMAGE_URL;
  const handlePrev = () => {
    const newIndex = currentImage === 0 ? images.length - 1 : currentImage - 1;
    setCurrentImage(newIndex);
  };

  const handleNext = () => {
    const newIndex = currentImage === images.length - 1 ? 0 : currentImage + 1;
    setCurrentImage(newIndex);
  };
  const imageUrl = images ? `${imagePath}/${images[currentImage].trim()}` : '';
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={alert.analytic_type}
      maxWidth="lg"
      sx={{
        '.MuiPaper-root': {
          maxWidth: '100% !important',
        },
      }}
      maxHeight="80vh"
    >
      <div
        className="image-gallery-container"
        style={{
          width: '100%',
          margin: '0 auto',
          position: 'relative',
          maxWidth: 'unset',
        }}
      >
        <button className="prev-btn" onClick={handlePrev}>
          <ArrowBackIosNewIcon />
        </button>
        <div
          style={{
            width: '100%',
            overflow: 'auto',
            maxWidth: '160vh',
            height: '78vh',
          }}
        >
          <div className="image-container" style={{ width: '100%' }}>
            <img
              src={imageUrl}
              alt=""
              className="current-image"
              style={{ width: '100%', height: '78vh', objectFit: 'contain' }}
            />
          </div>
        </div>

        <button className="next-btn" onClick={handleNext}>
          <ArrowForwardIosIcon />
        </button>
      </div>
    </BaseModal>
  );
};

export default ImageGallery;
