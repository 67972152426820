import { apolloClient } from '../apolloClient';
import {
  GET_ALL_USERS,
  GENERATE_USER_LIST_PDF,
  GENERATE_USER_LIST_EXCEL,
} from '../user';

export const getAllUsers = async ({
  sortBy,
  sortOrder,
  filter,
  search,
  page,
}) => {
  try {
    const response = await apolloClient.query({
      query: GET_ALL_USERS,
      variables: { sortBy, sortOrder, filter, search, page },
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const generateUserListPdf = async ({
  sortBy,
  sortOrder,
  filter,
  search,
}) => {
  try {
    const response = await apolloClient.query({
      query: GENERATE_USER_LIST_PDF,
      variables: { sortBy, sortOrder, filter, search },
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};

export const generateUsersListExcel = async ({
  sortBy,
  sortOrder,
  filter,
  search,
}) => {
  try {
    const response = await apolloClient.query({
      query: GENERATE_USER_LIST_EXCEL,
      variables: { sortBy, sortOrder, filter, search },
    });
    return response;
  } catch (error) {
    console.error('Login error:', error);
    // Handle login error
  }
};
