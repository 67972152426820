import { FormControl, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CloseEyeIcon, OpenEyeIcon } from '../Icons/SVGContainer';

const PasswordField = (props) => {
  const { name, label, placeholder, onChange, error, helperText } = props;
  const [password, setPassword] = useState(false);
  const togglePassword = () => {
    setPassword(!password);
  };
  return (
    <div>
      <Typography component="label">{label}</Typography>
      <FormControl fullWidth>
        <TextField
          name={name}
          type={password ? 'text' : 'password'}
          placeholder={placeholder}
          onChange={onChange}
          error={error}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <IconButton className="passwordIcon" onClick={togglePassword}>
                {!password ? <CloseEyeIcon /> : <OpenEyeIcon />}
              </IconButton>
            ),
          }}
        />
      </FormControl>
    </div>
  );
};

export default PasswordField;
