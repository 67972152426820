import React from 'react';

const WorkInProgressPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Work in Progress</h1>
      <p>This page is currently under development. Please check back later.</p>
    </div>
  );
};

export default WorkInProgressPage;
