import { Box, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import {
  AlertIcon,
  DashboardIcon,
  RoleIcon,
  SettingsIcon,
  UserIcon,
  AppIcon,
  CameraIcon,
  VideoAlertIcon,
} from '../Icons/SVGContainer';
import useAuth from '../Redux/Actions/authActions';

const MenuItems = [
  {
    route: '/dashboard',
    icon: <DashboardIcon />,
    label: 'Dashboard',
  },
  // {
  //   route: '/maps',
  //   icon: <AppIcon />,
  //   label: 'Maps',
  // },
  {
    route: '/role-list',
    icon: <RoleIcon />,
    label: 'Role',
  },
  {
    route: '/user-list',
    icon: <UserIcon />,
    label: 'User',
  },
  {
    route: '/settings/applications',
    icon: <AppIcon />,
    label: 'Applications',
  },
  {
    route: '/settings/analytics',
    icon: <AppIcon />,
    label: 'Analytics',
  },
  {
    route: '/settings/cameras',
    icon: <CameraIcon />,
    label: 'Cameras',
  },
  {
    route: '/alerts',
    icon: <AlertIcon />,
    label: 'Alerts',
  },
  {
    // {
    //   route: '/maps',
    //   icon: <AppIcon />,
    //   label: 'Maps',
    // },
    route: '/video_summarization',
    icon: <VideoAlertIcon />,
    label: 'VS Alerts',
  },
  {
    route: '/video-upload',
    icon: <AppIcon />,
    label: 'Video Upload',
  },
  // {
  //   route: '/vms-and-third-party-integration',
  //   icon: <AppIcon />,
  //   label: 'VMS & Third Party Integration',
  // },
];

const Menu = (props) => {
  const { user } = useAuth();
  return (
    <>
      {MenuItems.map((menuItem, index) => {
        if (
          menuItem.label === 'Role' ||
          menuItem.label === 'User' ||
          menuItem.label === 'Applications' ||
          menuItem.label === 'Alerts' ||
          menuItem.label === 'VS Alerts' ||
          menuItem.label === 'Cameras' ||
          menuItem.label === 'Analytics' ||
          menuItem.label === 'Video Upload' ||
          menuItem.label === 'Dashboard' ||
          menuItem.label === 'Maps' ||
          menuItem.label === 'VMS & Third Party Integration'
        ) {
          return (
            <Fragment key={menuItem.label}>
              {menuItem.label === 'Role' && user?.userType === 'Admin' && (
                <Typography component="li">
                  <NavLink
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    to={menuItem.route}
                  >
                    <Box>{menuItem.icon}</Box>
                    {menuItem.label}
                  </NavLink>
                </Typography>
              )}
              {menuItem.label !== 'Role' && (
                <Typography key={menuItem.label} component="li">
                  <NavLink
                    to={menuItem.route}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                  >
                    <Box>{menuItem.icon}</Box>
                    {menuItem.label}
                  </NavLink>
                </Typography>
              )}
            </Fragment>
          );
        } else {
          return (
            <Typography
              key={menuItem.label}
              component="li"
              style={{ opacity: 0.5 }}
            >
              <NavLink
                to={menuItem.route}
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <Box>{menuItem.icon}</Box>
                {menuItem.label}
              </NavLink>
            </Typography>
          );
        }
      })}
    </>
  );
};

export default Menu;
