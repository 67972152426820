import React, { useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  ActiveIcon,
  DeleteIcon,
  EditIcon,
  InActiveIcon,
  OpenBorderEyeIcon,
  SettingIcon,
} from '../../Icons/SVGContainer';
import { useNavigate } from 'react-router-dom';
import { getUsersByRole } from '../../graphQL/Role/query';
import BaseModal from '../Modal/BaseModal';
import UserListModal from '../Modal/UserListModal';

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function DeleteRoleDialog({ isOpen, onClose, onDelete }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete Role?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ color: '#fff' }}>
          Are you sure you want to delete this role?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const RoleDataTable = ({
  roles = [],
  headCells,
  columns,
  onClick,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [roleId, setRoleId] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false);

  const removeRole = (id) => {
    setIsOpen(true);
    setRoleId(id);
  };

  const getUniquePermissions = (role) => {
    const modules = [];
    role.rolePermissions?.forEach((rolePermission) => {
      if (rolePermission?.permission.module) {
        if (!modules.includes(rolePermission.permission.module)) {
          modules.push(rolePermission.permission.module);
        }
      }
    });

    return modules.map((val) => {
      return (
        <Avatar key={val}>{val.charAt(0).toUpperCase() + val.slice(1)}</Avatar>
      );
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    onDelete(roleId);
    setIsOpen(false);
  };
  const navigate = useNavigate();

  const showUsers = async (roleID) => {
    const resp = await getUsersByRole(roleID);
    if (resp.data?.getUsersByRole?.users) {
      setUserList(resp.data.getUsersByRole.users);
    }
    setUserModalOpen(true);
  };

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={'medium'}
        className="datatable_common"
      >
        <EnhancedTableHead rowCount={roles.length} headCells={headCells} />
        <TableBody>
          {roles.length
            ? roles.map((role) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={role.id}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell key="title" align="right">
                      {role.title}
                    </TableCell>
                    <TableCell key="users" align="right">
                      <IconButton onClick={() => showUsers(role.id)}>
                        <OpenBorderEyeIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell key="permissions" align="right">
                      <AvatarGroup sx={{ justifyContent: 'start' }}>
                        {getUniquePermissions(role)}
                      </AvatarGroup>
                    </TableCell>
                    <TableCell key="last_updated" align="right">
                      {new Date(parseInt(role.updatedAt)).toDateString()}
                    </TableCell>
                    <TableCell key="status" align="right">
                      {/* <Switch
                    checked={role.active}
                    // onClick={() => changeStatus(role.id)}
                  /> */}
                      {role.active ? (
                        <Chip
                          icon={<ActiveIcon />}
                          label="Active"
                          className="active_data"
                        />
                      ) : (
                        <Chip
                          icon={<InActiveIcon />}
                          label="Inactive"
                          className="inactive_data"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Box className="action_icon">
                        <IconButton>
                          <DeleteIcon onClick={() => removeRole(role.id)} />
                        </IconButton>
                        <IconButton>
                          <EditIcon onClick={() => onClick(role.id)} />
                        </IconButton>
                        <IconButton>
                          <SettingIcon
                            onClick={() => navigate(`/permission/${role.id}`)}
                          />
                        </IconButton>
                        <DeleteRoleDialog
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          onClose={handleClose}
                          onDelete={handleDelete}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            : 'No roles found'}
        </TableBody>
      </Table>
      <UserListModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        title={`User List`}
        userList={userList}
      />
    </TableContainer>
  );
};

export default RoleDataTable;
