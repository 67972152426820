import { toast } from 'react-toastify';
import { apolloClient } from '../apolloClient';
import { CREATE_ROLE, DELETE_ROLE, EDIT_ROLE } from '../role';
import { ASSIGN_PERMISSION } from '../permission';

export const createRole = async ({ title, status }) => {
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_ROLE,
      variables: {
        title: title,
        status: status,
      },
    });
    toast.success('Role created successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Role title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const updateRole = async ({ id, title, status }) => {
  try {
    const response = await apolloClient.mutate({
      mutation: EDIT_ROLE,
      variables: {
        editRoleId: id,
        title: title,
        status: status,
      },
    });
    toast.success('Role updated successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    if (error.message.includes('exist')) {
      toast.error('Role title exist', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    throw new Error(error.message);
  }
};

export const deleteRole = async (id) => {
  try {
    const response = await apolloClient.mutate({
      mutation: DELETE_ROLE,
      variables: {
        deleteRoleId: id,
      },
    });
    toast.success('Role deleted successfully!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response;
  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const assignPermission = async ({ roleId, permissions }) => {
  const response = await apolloClient.mutate({
    mutation: ASSIGN_PERMISSION,
    variables: {
      roleId: roleId,
      permissions: permissions,
    },
  });
  toast.success('Permission saved successfully!', {
    position: toast.POSITION.TOP_RIGHT,
  });
  return response;
};
