import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import { UserStyle } from '../../../Layouts/style';
import { Pagination, Stack } from '@mui/material';
import BaseModal from '../../../Components/Modal/BaseModal';
import AdminBusinessHeading from './AdminBusinessHeading';
import AddBusinessForm from '../../../Components/Form/AddBusinessForm';
import BusinessDataTable from '../../../Components/DataTable/BusinessDatatable';

const AdminBusinessList = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const columns = ['fullName', 'emailAddress', 'companyName', 'location', 'businessType', 'status', 'action'];
    const headCells = [
        {
            id: 'fullName',
            numeric: false,
            disablePadding: true,
            label: 'Full Name',
        },
        {
            id: 'emailAddress',
            numeric: false,
            disablePadding: true,
            label: 'Email address',
        },
        {
            id: 'companyName',
            numeric: false,
            disablePadding: true,
            label: 'Company Name',
        },
        {
            id: 'location',
            numeric: false,
            disablePadding: true,
            label: 'Location',
        },
        {
            id: 'businessType',
            numeric: false,
            disablePadding: true,
            label: 'Business Type',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: true,
            label: 'Status',
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: true,
            label: 'Action',
        },
    ]

    const rows = [
        { fullName: 'Admin', emailAddress: 'admin@demo.com', companyName: 'pitangent', location: 'Kolkata', businessType: 'software company', status: 'true', action: 'true' },
        { fullName: 'Admin', emailAddress: 'admin@demo.com', companyName: 'pitangent', location: 'Kolkata', businessType: 'software company', status: 'true', action: 'true' },
        { fullName: 'Admin', emailAddress: 'admin@demo.com', companyName: 'pitangent', location: 'Kolkata', businessType: 'software company', status: 'true', action: 'true' },
        { fullName: 'Admin', emailAddress: 'admin@demo.com', companyName: 'pitangent', location: 'Kolkata', businessType: 'software company', status: 'true', action: 'true' },
        { fullName: 'Admin', emailAddress: 'admin@demo.com', companyName: 'pitangent', location: 'Kolkata', businessType: 'software company', status: 'true', action: 'true' },
    ];
    return (
        <UserStyle>
            <Helmet>
                <title>Mirasys: Users</title>
            </Helmet>
            <Box className="user_area">
                <AdminBusinessHeading />
                <BusinessDataTable
                    rows={rows}
                    headCells={headCells}
                    columns={columns}
                    onClick={() => setModalOpen(true)}
                />
                {/* <Stack spacing={2}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Stack> */}
                <BaseModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    title={`Edit`}
                >
                    <AddBusinessForm
                        onClose={() => setModalOpen(false)}
                    />
                </BaseModal>
            </Box>
        </UserStyle>
    );
};

export default AdminBusinessList;
