import { gql } from '@apollo/client';

export const GET_ALL_VIDEOS = gql`
query Videos( 
    $sortBy: String
    $sortOrder: String
    $page: Int
    ) {
    getAllVideoSummerzied(
        sortBy: $sortBy
        sortOrder: $sortOrder
        page: $page
        ) {
      videos {
        id
        name
        description
        start_time
        video_url
        createdAt
        created_by {
          firstname
          lastname
        }
      }
      count
      totalPages
    }
  }
`;


