import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Tabs,
  Tab,
  Stack,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { ApplicationStyle } from '../../../Layouts/style';
import {
  DropDeleteIcon,
  DropEditIcon,
  GridIcon,
  ListIcon,
  VerticalDotIcon,
  SearchIcon,
  CloseIcon,
  AscSortIcon,
  DescSortIcon,
} from '../../../Icons/SVGContainer';
import BaseModal from '../../../Components/Modal/BaseModal';
import AnalyticsListDataTable from '../../../Components/DataTable/AnalyticsListDatatable.';
import EditAnalyticsForm from '../../../Components/Form/EditAnalyticsForm';
import AnalyticsService from '../../../RestSevices/analyticsService';
import { toast } from 'react-toastify';
import useAuth from '../../../Redux/Actions/authActions';
import { checkHasObjPermission } from '../../../Utils/checkPermission';

function DeleteAnalyticsDialog({ deleteIsOpen, deleteOnClose, onDelete }) {
  return (
    <Dialog
      open={deleteIsOpen}
      onClose={deleteOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete Analytics?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this analytics?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteOnClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function sortProps(index) {
  return {
    id: `sort-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AnalyticsListView = () => {
  const [applyDropdown, setApplyDropdown] = useState(null);
  const [value, setValue] = useState('one');
  const [analyticsForEdit, setAnalyticsForEdit] = useState({});
  const [clickedAnalytics, setClickedAnalytics] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [analyticsList, setAnalyticsList] = useState([]);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const { logout } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortValue, setSortValue] = React.useState('asc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOptionClick = (event, hashId) => {
    setApplyDropdown(hashId);
    setClickedAnalytics(hashId);
  };

  const handleClose = () => {
    setApplyDropdown(null);
  };

  const fetchAnalytics = () => {
    AnalyticsService.getAnalyticsList(page, searchTerm, sortValue)
      .then((resp) => {
        const allAnalytics = resp.data.responseData.data;
        setAnalyticsList(
          allAnalytics.map((analytics) => {
            return {
              ...analytics,
              id: analytics.analytic_id,
            };
          })
        );
        setTotalPages(resp.data.responseData.totalPages);
      })
      .catch((error) => {
        if (error.response?.data?.error === 'Authentication failed') {
          logout();
          toast.error('Session Expired!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handleDeleteConfirm = () => {
    setDeleteIsOpen(true);
    handleClose();
  };

  const handleOpenForm = () => {
    setModalOpen(!modalOpen);
  };

  const handleDelete = async () => {
    try {
      await AnalyticsService.deleteAnalytics(clickedAnalytics)
        .then(() => {
          setDeleteIsOpen(false);
          fetchAnalytics();
          toast.success('Analytics deleted successfully!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          if (error.response?.data?.error === 'Authentication failed') {
            logout();
            toast.error('Session Expired!', {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            error.response?.data?.message.includes('There are associated zones')
          ) {
            setDeleteIsOpen(false);
            toast.error(error.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    } catch (err) {
      toast.error('Error in deleting analytics', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleEdit = (hash_id) => {
    const selectedAnalytic = analyticsList.find(
      (analytic) => analytic.hash_id === hash_id
    );
    setAnalyticsForEdit(selectedAnalytic);
    setModalOpen(true);
    handleClose();
  };

  const handleChangeSort = (event, newValue) => {
    setSortValue(newValue);
  };

  const handlePageChange = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const clearSearchTerm = () => {
    const clearedSearchTerm = '';
    setSearchTerm(clearedSearchTerm);
  };

  const columns = ['analytic_name', 'analytic_description', 'action'];
  const headCells = [
    {
      id: 'analyticsName',
      numeric: true,
      disablePadding: true,
      label: 'Analytics Name',
    },
    {
      id: 'description',
      numeric: true,
      disablePadding: true,
      label: 'Description',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
    },
  ];

  useEffect(() => {
    fetchAnalytics();
  }, [page, searchTerm, sortValue]);

  useEffect(() => {
    fetchAnalytics();
  }, []);
  return (
    <ApplicationStyle>
      <Box className="application_page_heading">
        <Typography component="h2">Analytics</Typography>
      </Box>
      <Box className="tab_area">
        <Box className="tab_toggle_area">
          <Box className="page_search">
            <TextField
              type="text"
              placeholder="Searching for....."
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchTerm && (
                  <IconButton onClick={clearSearchTerm}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Tabs
              value={sortValue}
              onChange={handleChangeSort}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="asc"
                label={<AscSortIcon />}
                {...sortProps('asc')}
                className="tab_btn sort_tab_btn"
              />
              <Tab
                value="desc"
                label={<DescSortIcon />}
                {...sortProps('desc')}
                className="tab_btn sort_tab_btn"
              />
            </Tabs>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
            >
              <Tab
                value="one"
                label={<GridIcon />}
                {...a11yProps('one')}
                className="tab_btn"
              />
              <Tab
                value="two"
                label={<ListIcon />}
                {...a11yProps('two')}
                className="tab_btn"
              />
            </Tabs>
          </Box>
        </Box>
        <TabPanel value={value} index="one">
          <Box className="apply_box_area">
            {analyticsList.length > 0 ? (
              analyticsList.map((data) => {
                return (
                  <Box className="apply_box" key={data.analytic_id}>
                    <Box className="apply_box_heading">
                      <Typography compdeleteOnCloseonent="h4">
                        Analytics name
                      </Typography>
                      <Typography component="h3" className="text_truncate">
                        {data.analytic_name}
                      </Typography>
                      <IconButton
                        className="application_menu"
                        onClick={(event) =>
                          handleOptionClick(event, data.hash_id)
                        }
                      >
                        <VerticalDotIcon />
                      </IconButton>
                      <Box
                        className={
                          applyDropdown === data.hash_id
                            ? 'applydropbox show'
                            : 'applydropbox'
                        }
                      >
                        {checkHasObjPermission(
                          data,
                          'analytic',
                          'edit_analytic'
                        ) && (
                          <Button onClick={() => handleEdit(data.hash_id)}>
                            Edit <DropEditIcon />
                          </Button>
                        )}
                        {checkHasObjPermission(
                          data,
                          'analytic',
                          'delete_analytic'
                        ) && (
                          <Button onClick={handleDeleteConfirm}>
                            Delete <DropDeleteIcon />
                          </Button>
                        )}
                      </Box>
                    </Box>
                    <Box className="apply_box_info">
                      <Box className="apply_box_info_con">
                        <Typography component="h4">Description</Typography>
                        <Typography component="h3" className="text_truncate">
                          {data.analytic_description}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Typography
                variant="h4"
                component="div"
                style={{ textAlign: 'center' }}
              >
                No analytic found
              </Typography>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={value} index="two">
          {analyticsList.length === 0 ? (
            <Typography
              variant="h4"
              component="div"
              style={{ textAlign: 'center' }}
            >
              No analytic found
            </Typography>
          ) : (
            <AnalyticsListDataTable
              rows={analyticsList}
              headCells={headCells}
              columns={columns}
              handleOpenForm={handleOpenForm}
              handleOptionClick={handleOptionClick}
              handleEdit={handleEdit}
              handleDeleteConfirm={handleDeleteConfirm}
            />
          )}
        </TabPanel>
        <Stack spacing={2}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
        <BaseModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          title="Edit Analytics"
        >
          <EditAnalyticsForm
            onClose={() => setModalOpen(false)}
            analyticsForEdit={analyticsForEdit}
            fetchAnalytics={fetchAnalytics}
          />
        </BaseModal>
        <DeleteAnalyticsDialog
          deleteIsOpen={deleteIsOpen}
          deleteOnClose={() => setDeleteIsOpen(false)}
          onDelete={handleDelete}
        />
      </Box>
    </ApplicationStyle>
  );
};

export default AnalyticsListView;
