import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AddIcon, SearchIcon, CloseIcon } from '../../../Icons/SVGContainer';
import { DatatableHeadingStyle } from '../../../Layouts/style';
import BaseModal from '../../../Components/Modal/BaseModal';
import AddRoleForm from '../../../Components/Form/AddRoleForm';

const RoleHeading = ({ fetchRoles, handleChangeList }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    handleChangeList(searchTerm);
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const clearSearchTerm = () => {
    const clearedSearchTerm = '';
    setSearchTerm(clearedSearchTerm);
   
  };
  return (
    <DatatableHeadingStyle>
      <Box className="user_heading">
        <Typography component="h2">Role management</Typography>
        <Box className="user_head_search">
          <Box className="page_search">
            <TextField
              type="text"
              placeholder="Searching for....."
              InputProps={{
                startAdornment: <SearchIcon />,
                endAdornment: searchTerm && (
                  <IconButton onClick={clearSearchTerm}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box className="table_head_btn_con">
            <IconButton onClick={() => setModalOpen(true)}>
              <AddIcon /> Add Role
            </IconButton>
          </Box>
          <BaseModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            title={`Add Role`}
          >
            <AddRoleForm
              onClose={() => setModalOpen(false)}
              fetchRoles={fetchRoles}
            />
          </BaseModal>
        </Box>
      </Box>
    </DatatableHeadingStyle>
  );
};

export default RoleHeading;
