import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';
import AnalyticsService from '../../RestSevices/analyticsService';
import { toast } from 'react-toastify';

const EditAnalyticsForm = ({ onClose, analyticsForEdit, fetchAnalytics }) => {
  const [formData, setFormData] = useState({});
  const handleValueChange = (event, field) => {
    setFormData({
      ...formData,
      analyticfields: formData.analyticfields.map((item) => {
        let value = event.target.value;
        if (item.hash_id == field.hash_id) {
          if (field.field_type === 'string' && value) {
            // Remove any non-numeric characters
            if (value.length > 100) {
              return item;
            }
          }
    
          if (field.field_type === 'integer' && value) {
            // Remove any non-numeric characters
            value = value.replace(/[^\d]/g, '');
            if (value.length > 9) {
              return item;
            }
            value = parseInt(value, 10);
            if (isNaN(value)) {
              value = field.field_value; // Retain the previous value if not valid
            }
          }
      
          if (field.field_type === 'float') {
            // Allow digits and a single decimal point
            value = value.replace(/[^\d.]/g, '');
      
            // Ensure there's only one decimal point
            const decimalPointCount = (value.match(/\./g) || []).length;
            if (decimalPointCount > 1) {
              return item;
            }
      
            // Check the length of the integer and fractional parts
            const parts = value.split('.');
            const integerPart = parts[0];
            const fractionalPart = parts[1] || '';
      
            if (integerPart.length > 3 || fractionalPart.length > 2) {
              return item;
            }
          }
      
          if (field.field_type === 'array' && value) {
            value = value.replace(/[^\w,]/g, '');
            if (value.length > 200) {
              return item;
            }
            value = value.split(','); // Parse the value as an array
          }

          if (field.field_type === 'obj' && value) {
            if (value.length > 200) {
              return item;
            } // Parse the value as an array
          }
       
          item.field_value = value;
          return item;
        } else {
          return item;
        }
      }),
    });
  };

  const handleUpdate = (event) => {
    event.preventDefault();

    const postData = {
      analyticfields: formData.analyticfields.map((data) => {
        return {
          field_value: data.field_value,
          field_hashid: data.hash_id,
          field_type: data.field_type,
        };
      }),
    };

    AnalyticsService.updateAnalytics(formData.hash_id, postData).then(
      (resp) => {
        fetchAnalytics();
        onClose();
        toast.success('Analytics updated successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  useEffect(() => {
    setFormData(analyticsForEdit);
  }, [analyticsForEdit]);

  const renderFieldValue = (field, handleValueChange) => {
    if (field.field_type === 'boolean') {
      return (
        <FormControl>
          <Select
            value={field.field_value}
            onChange={(event) => handleValueChange(event, field)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>
      );
    } else if (field.field_type === 'array') {
      return (
        <TextField
          name="field_value"
          placeholder="Enter value"
          value={field.field_value.join(',')}
          onChange={(event) => handleValueChange(event, field)}
        />
      );
    } else {
      return (
        <TextField
          name="field_value"
          placeholder="Enter value"
          value={field.field_value}
          onChange={(event) => handleValueChange(event, field)}
        />
      );
    }
  };

  return (
    <ModalLayoutStyle>
      <Box className="modal_form_body">
        <form onSubmit={handleUpdate}>
          <Box className="form_field_area analytics_form">
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Analytics Name</Typography>
                <TextField
                  name="analytics_name"
                  placeholder="Enter name"
                  value={formData.analytic_name}
                  disabled={true}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Description</Typography>
                <TextField
                  name="analytics_desc"
                  placeholder="Type..."
                  multiline
                  rows={3}
                  value={formData.analytic_description}
                  disabled={true}
                />
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Box className="form_box_con">
                <Typography component="label">Priority</Typography>
                <FormControl>
                  <Select
                    value={formData?.severity || ''}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    disabled={true}
                  >
                    <MenuItem value={'1'}>High</MenuItem>
                    <MenuItem value={'2'}>Medium</MenuItem>
                    <MenuItem value={'3'}>Low</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="form_box_hold">
              <Typography component="h3">Add Fields</Typography>
            </Box>
            {formData.analyticfields?.map((field) => {
              return (
                <Box className="form_box_hold" key={field.hash_id}>
                  <Box className="form_box_con">
                    <Typography component="label">Field name</Typography>
                    <TextField
                      name="field_name"
                      placeholder="Enter name"
                      value={field.field_name}
                      disabled={true}
                    />
                  </Box>
                  <Box className="form_box_con">
                    <Typography component="label">Value</Typography>
                    {renderFieldValue(field, handleValueChange)}
                  </Box>
                  <Box className="form_box_con">
                    <Typography component="label">Data type</Typography>
                    <TextField
                      name="data_type"
                      placeholder="Boolean"
                      value={field.field_type}
                      disabled={true}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box className="add_btn">
            <Button className="cancel_btn" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Box>
        </form>
      </Box>
    </ModalLayoutStyle>
  );
};

export default EditAnalyticsForm;
