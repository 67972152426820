import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
} from '@mui/material';
import { dashboardSettingsValidationSchema } from '../../../Utils/validators';
import { AddForm, InputBox, Button } from '../../../Layouts/style';

const InputField = ({
  label,
  name,
  value,
  onChange,
  touched,
  errors,
  children,
}) => (
  <InputBox>
    <Typography component="span">{label}</Typography>
    {children ? (
      children
    ) : (
      <TextField
        id="outlined-basic"
        className="input"
        name={name}
        value={value}
        onChange={onChange}
      />
    )}
    <FormHelperText error={touched && !!errors}>
      {touched ? errors : ''}
    </FormHelperText>
  </InputBox>
);

const SelectField = ({
  label,
  name,
  value,
  onChange,
  touched,
  errors,
  options,
  multiple = false,
  disabledOptions = [],
}) => (
  <InputBox>
    <Typography component="span">{label}</Typography>
    <Select
      className="select"
      name={name}
      value={value}
      onChange={onChange}
      displayEmpty
      multiple={multiple}
    >
      {options.map((option) => (
        <MenuItem
          value={option.value}
          key={option.value}
          disabled={disabledOptions.includes(option.value)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText error={touched && !!errors}>
      {touched ? errors : ''}
    </FormHelperText>
  </InputBox>
);

const ChartSpecificFields = ({
  formdata,
  index,
  handleChange,
  props,
  classList,
}) => {
  if (formdata.chart === 'bar_chart') {
    return (
      <>
        <SelectField
          label="Select X axis"
          name="x_axis"
          value={formdata.x_axis}
          onChange={(e) => handleChange(e, index, props)}
          touched={props.touched.x_axis}
          errors={props.errors.x_axis}
          options={[
            { value: '', label: <em>None</em> },
            { value: 'time', label: 'Time' },
          ]}
        />
        <SelectField
          label="Select Y axis"
          name="y_axis"
          value={formdata.y_axis}
          onChange={(e) => handleChange(e, index, props)}
          touched={props.touched.y_axis}
          errors={props.errors.y_axis}
          options={[
            { value: '', label: <em>None</em> },
            { value: 'total_count', label: 'Total Count' },
          ]}
        />
      </>
    );
  }

  if (formdata.chart === 'pie_chart' || formdata.chart === 'line_chart') {
    return (
      <SelectField
        label="Chart parameters"
        name="chart_parameters"
        value={formdata.chart_parameters}
        onChange={(e) => handleChange(e, index, props)}
        touched={props.touched.chart_parameters}
        errors={props.errors.chart_parameters}
        options={[
          {
            value: 'none',
            label: <em>None</em>,
            disabled:
              formdata.chart === 'pie_chart' ||
              (formdata.chart_parameters.length &&
                !formdata.chart_parameters.includes('none')),
          },
          ...classList
            .filter((type) => type !== formdata.parameter)
            .map((type) => ({
              value: type,
              label: type,
              disabled: formdata.chart_parameters.includes('none'),
            })),
        ]}
        multiple
      />
    );
  }

  return null;
};

const FormContainer = ({
  formdata,
  index,
  handleChange,
  handleSubmit,
  handleDeleteConfirm,
  props,
  classList,
  statsTypes,
}) => {
  const chart_types = [
    { title: 'No chart', slug: 'no_chart' },
    { title: 'Bar chart', slug: 'bar_chart' },
    { title: 'Pie chart', slug: 'pie_chart' },
    { title: 'Line chart', slug: 'line_chart' },
  ];
  return (
    <Box className="add-form" key={formdata.id + 'frm'}>
      <Typography component="p">{`Add stats +(${index + 1})`}</Typography>
      <AddForm className="formMain" onSubmit={props.handleSubmit}>
        <InputField
          label="Stats name"
          name="name"
          value={formdata.name}
          onChange={(e) => handleChange(e, index, props)}
          touched={props.touched.name}
          errors={props.errors.name}
        />
        <SelectField
          label="Chart type"
          name="chart"
          value={formdata.chart}
          onChange={(e) => handleChange(e, index, props)}
          touched={props.touched.chart}
          errors={props.errors.chart}
          options={chart_types.map((type) => ({
            value: type.slug,
            label: type.title,
          }))}
        />
        {formdata.chart !== 'pie_chart' && (
          <SelectField
            label="Stats parameter"
            name="parameter"
            value={formdata.parameter}
            onChange={(e) => handleChange(e, index, props)}
            touched={props.touched.parameter}
            errors={props.errors.parameter}
            options={[
              { value: 'none', label: <em>None</em> },
              ...classList.map((type) => ({ value: type, label: type })),
            ]}
          />
        )}
        <SelectField
          label="Type of stats"
          name="stats_type"
          value={formdata.stats_type}
          onChange={(e) => handleChange(e, index, props)}
          touched={props.touched.stats_type}
          errors={props.errors.stats_type}
          options={[
            { value: '', label: <em>None</em> },
            ...statsTypes.map((type) => ({
              value: type.id,
              label: type.title,
            })),
          ]}
        />
        <ChartSpecificFields
          formdata={formdata}
          index={index}
          handleChange={handleChange}
          props={props}
          classList={classList}
        />
        {formdata.id ? (
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                type="button"
                onClick={() => handleDeleteConfirm(formdata.id)}
              >
                Delete
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit">Update</Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button type="submit">Create</Button>
            </Grid>
          </Grid>
        )}
      </AddForm>
    </Box>
  );
};

const DashboardSettingsForm = ({
  forms,
  handleSubmit,
  handleChange,
  handleDeleteConfirm,
  classList,
  statsTypes,
}) => {
  return (
    <>
      {forms.map((formdata, index) => (
        <Formik
          key={formdata.id}
          initialValues={formdata}
          validationSchema={dashboardSettingsValidationSchema}
          onSubmit={(values, { resetForm }) =>
            handleSubmit(values, { resetForm }, index)
          }
          enableReinitialize
        >
          {(props) => (
            <FormContainer
              formdata={formdata}
              index={index}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleDeleteConfirm={handleDeleteConfirm}
              props={props}
              classList={classList}
              statsTypes={statsTypes}
            />
          )}
        </Formik>
      ))}
    </>
  );
};

export default DashboardSettingsForm;
