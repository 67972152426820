import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import AuthLayout from '../Layouts/AuthLayout';
import MainLayout from '../Layouts/MainLayout';
import Login from '../Pages/AuthPage/Login';
import Signup from '../Pages/AuthPage/Signup';
import ForgotPassword from '../Pages/AuthPage/ForgotPassword';
import CheckEmail from '../Pages/AuthPage/CheckEmail';
import NewPassword from '../Pages/AuthPage/NewPassword';
import ResetPassword from '../Pages/AuthPage/ResetPassword';
import UserList from '../Pages/DashboardPage/Users/UserList';
import useAuth from '../Redux/Actions/authActions';
import ChangePassword from '../Pages/AuthPage/ChangePassword';
import UserChangePassword from '../Pages/DashboardPage/UserChangePassword/UserChangePassword';
import RoleList from '../Pages/DashboardPage/Role/RoleList';
import AdminBusinessList from '../Pages/DashboardPage/AdminBusiness/AdminBusinessList';
import PermissionView from '../Pages/DashboardPage/Permission/PermissionView';
import ApplicationListView from '../Pages/DashboardPage/Application/ApplicationListView';
import CameraListView from '../Pages/DashboardPage/Camera/CameraListView';
import WorkInProgress from '../Pages/DashboardPage/WIP';
import AnalyticsListView from '../Pages/DashboardPage/Analytics/AnalyticsListView';
import ZoneConfigurationView from '../Pages/DashboardPage/ZoneConfiguration/ZoneConfiguratioView';
import VideoUploadView from '../Pages/DashboardPage/VideoUpload/VideoUploadView';
import DashboardView from '../Pages/DashboardPage/Dashboard/DashboardView';
import DashboardSettings from '../Pages/DashboardPage/Dashboard/DashboardSetting';
import Alerts from '../Pages/DashboardPage/Alerts/Alerts';
import VideoSummarizationAlerts from '../Pages/DashboardPage/VideoSummarization/VideoSummarizationAlerts';
import MapView from '../Pages/DashboardPage/Maps/MapView';
import VmsAndThirdParty from '../Pages/DashboardPage/VmsAndThirdParty/VmsAndThirdParty';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (user.hasChangedPassword === 0) {
    return <Navigate to="/change-password" replace />;
  }

  return <>{children}</>;
};

const ChangePasswordGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (isAuthenticated && user.hasChangedPassword) {
    return <Navigate to="/user-list" replace />;
  }

  return <>{children}</>;
};

const PublicPageGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};

const NotFound = () => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.color.color3,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Oops! We can't seem to find that page.
        </Typography>
        <Typography variant="body1">
          The page you're looking for might have been removed or the URL might
          be incorrect.
        </Typography>
      </Box>
    </Box>
  );
};

const PathChecker = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== '/alerts') {
      localStorage.removeItem('analyticsSelection');
    }
  }, [location]);

  return null;
};

const RouteComponent = () => {
  return (
    <BrowserRouter>
      <PathChecker />
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<PublicPageGuard children={<Login />} />} />
          <Route
            path="login"
            element={<PublicPageGuard children={<Login />} />}
          />
          <Route
            path="signup"
            element={<PublicPageGuard children={<Signup />} />}
          />
          <Route
            path="forgot-password"
            element={<PublicPageGuard children={<ForgotPassword />} />}
          />
          <Route
            path="check-email"
            element={<PublicPageGuard children={<CheckEmail />} />}
          />
          <Route
            path="new-password"
            element={<PublicPageGuard children={<NewPassword />} />}
          />
          <Route
            path="reset-password"
            element={<PublicPageGuard children={<ResetPassword />} />}
          />
        </Route>
        <Route path="/" element={<MainLayout />}>
          {console.log('2222')}
          <Route
            path="user-list"
            element={<AuthGuard children={<UserList />} />}
          />
          <Route
            path="role-list"
            element={<AuthGuard children={<RoleList />} />}
          />
          <Route
            path="admin-business"
            element={<AuthGuard children={<AdminBusinessList />} />}
          />
          <Route
            path="settings/analytics"
            element={<AuthGuard children={<AnalyticsListView />} />}
          />
          <Route
            path="permission/:id"
            element={<AuthGuard children={<PermissionView />} />}
          />
          <Route
            path="settings/applications"
            element={<AuthGuard children={<ApplicationListView />} />}
          />
          <Route
            path="settings/cameras"
            element={<AuthGuard children={<CameraListView />} />}
          />
          <Route
            path="user-password-change"
            element={<AuthGuard children={<UserChangePassword />} />}
          />
          <Route path="alerts" element={<AuthGuard children={<Alerts />} />} />
          <Route
            path="video_summarization"
            element={<AuthGuard children={<VideoSummarizationAlerts />} />}
          />
          <Route
            path="zone-configuration/:camerahashid"
            element={<AuthGuard children={<ZoneConfigurationView />} />}
          />
          <Route
            path="video-upload"
            element={<AuthGuard children={<VideoUploadView />} />}
          />
          <Route
            path="dashboard"
            element={<AuthGuard children={<DashboardView />} />}
          />
          <Route
            path="dashboard-settings"
            element={<AuthGuard children={<DashboardSettings />} />}
          />

          <Route
            path="vms-and-third-party-integration"
            element={<AuthGuard children={<VmsAndThirdParty />} />}
          />
          {/* Add other authenticated routes within MainLayout */}
        </Route>
        {console.log('3333')}
        <Route
          path="/change-password"
          element={<ChangePasswordGuard children={<ChangePassword />} />}
        />
        {console.log('4444')}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteComponent;
