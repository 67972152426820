import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import BaseModal from '../Modal/BaseModal';
import ImageGalleryModal from '../ImageGallery';
import TimestampToDate from '../TimestampToDate';

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow className="table_head">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const VideoSummarizationDataTable = ({ rows, headCells, columns, onClick }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertClick, setAlertClick] = useState({});
  const visibleRows = rows;
  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={'medium'}
        className="datatable_common"
      >
        <EnhancedTableHead rowCount={rows.length} headCells={headCells} />
        <TableBody>
          {visibleRows.map((row) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: 'pointer' }}
              >
                {columns.map((col, index) => (
                  <TableCell align={index === 0 ? 'left' : 'right'} key={col}>
                    {col === 'thumbnailUrl' ? (
                      <Box
                        className="datatable_thumbnail"
                        onClick={() => {
                          setAlertClick(row);
                          setModalIsOpen(true);
                        }}
                      >
                        <IconButton>{row[col]}</IconButton>
                        {row.image_url.length > 1 && (
                          <Typography style={{ color: 'aquamarine' }}>{`+${
                            row.image_url.length - 1
                          }`}</Typography>
                        )}
                      </Box>
                    ) : col === 'startTime' ? (
                      <Box>
                        <Typography>
                          <TimestampToDate timestamp={row[col]} />
                        </Typography>
                      </Box>
                    ) : col === 'endTime' ? (
                      <Box>
                        <Typography>
                          <TimestampToDate timestamp={row[col]} />
                        </Typography>
                      </Box>
                    ) : (
                      row[col]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ImageGalleryModal
        alert={alertClick}
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        images={alertClick?.image_url}
      />
    </TableContainer>
  );
};

export default VideoSummarizationDataTable;
