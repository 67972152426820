import {
    Box,
    Button,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ModalLayoutStyle } from '../../Layouts/style';

const AddBusinessForm = ({ onClose }) => {
    const [status, setStatus] = React.useState('');
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };
    const [location, setLocation] = React.useState('');
    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };
    return (
        <ModalLayoutStyle>
            <Box className="modal_form_body">
                <form>
                    <Box className="form_field_area">
                        <Box className="form_box_hold">
                            <Box className="form_box_con">
                                <Typography component="label">Name</Typography>
                                <TextField
                                    name="name"
                                    type="text"
                                />
                            </Box>
                            <Box className="form_box_con">
                                <Typography component="label">Surname</Typography>
                                <TextField
                                    name="surname"
                                    type="text"
                                />
                            </Box>
                        </Box>
                        <Box className="form_box_hold">
                            <Box className="form_box_con">
                                <Typography component="label">Business Email</Typography>
                                <TextField
                                    name="business email"
                                    type="email"
                                />
                            </Box>
                            <Box className="form_box_con">
                                <Typography component="label">Company Name</Typography>
                                <TextField
                                    name="company name"
                                    type="text"
                                />
                            </Box>
                        </Box>
                        <Box className="form_box_con">
                            <Typography component="label">Location</Typography>
                            <Select
                                name="location"
                                value={location}
                                onChange={handleLocationChange}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    Location
                                </MenuItem>
                                <MenuItem value={'West Bengal'}>West Bengal</MenuItem>
                                <MenuItem value={'Jharkhand'}>Jharkhand</MenuItem>
                            </Select>
                        </Box>
                        <Box className="form_box_con">
                            <Typography component="label">Role</Typography>
                            <Select
                                name="role"
                                value={status}
                                onChange={handleStatusChange}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    Status
                                </MenuItem>
                                <MenuItem value={'Admin'}>Active</MenuItem>
                                <MenuItem value={'User'}>Inactive</MenuItem>
                            </Select>
                        </Box>
                    </Box>
                    <Box className="add_btn">
                        <Button className="cancel_btn" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit">
                            Create
                        </Button>
                    </Box>
                </form>
            </Box>
        </ModalLayoutStyle>
    );
};

export default AddBusinessForm;
