import { Box, Typography } from '@mui/material';
import React from 'react';

function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const HeaderDateInfo = ({ user }) => {
  return (
    <Box className="header_date">
      <Typography component="h2">
        Welcome{' '}
        {(user?.firstname && capitalizeFirstLetter(user?.firstname)) || ''}!
      </Typography>
      <Typography component="h3">{new Date().toDateString()}</Typography>
    </Box>
  );
};

export default HeaderDateInfo;
